import { useAppSelector } from 'app/store';
import { selectLeadership } from 'app/store/leadership/selectors';
import Leadership from 'app/types/Leadership';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const LeadershipSearchSection = ({ search }: SearchSectionProps) => {
  const leadershipTeams = useAppSelector(selectLeadership);
  const [leadershipResults, setLeadershipResults] = useState<Leadership[]>(
    [] as Leadership[],
  );
  const [leadershipCollapse, setLeadershipCollapse] = useState(true);
  const navigate = useNavigate();

  const clickLT = (id: string) => {
    navigate(`/leadership-teams/${id}`);
  };

  useEffect(() => {
    const filteredLeadership = leadershipTeams.filter(team =>
      team.name.toLowerCase().includes(search.toLowerCase()),
    );
    setLeadershipResults(filteredLeadership);
  }, [search]);

  return (
    <>
      <SearchHeader
        title="Leadership Teams"
        size={leadershipResults.length}
        collapse={leadershipCollapse}
        setCollapse={setLeadershipCollapse}
      />
      <LineBreak />
      <Results<Leadership>
        results={leadershipResults}
        collapse={leadershipCollapse}
        navigation={clickLT}
      />
    </>
  );
};
