import { Button, Modal, ModalFooter, ModalHeader } from 'cfa-react-components';
import { useState } from 'react';

export type SaveCloseModalProps = {
  isDisabled?: boolean;
  handleClose: () => void;
  handleSave: () => void;
};

export const SaveCloseModal = ({
  isDisabled,
  handleClose,
  handleSave,
}: SaveCloseModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        onClick={event => {
          setOpen(true);
        }}
        variant="outlined"
        size="md"
      >
        Cancel
      </Button>
      <Modal
        show={open}
        onClose={() => {
          setOpen(false);
        }}
        scrollMode="modal-body"
      >
        <ModalHeader>
          Are you sure you want to close without saving?
        </ModalHeader>
        <ModalFooter>
          <Button
            onClick={handleClose}
            variant="outlined"
            style={{
              marginRight: '5px',
            }}
          >
            Close Without Saving
          </Button>
          <Button
            onClick={handleSave}
            variant="filled"
            style={{
              marginLeft: '5px',
            }}
            disabled={isDisabled}
          >
            Save and Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
