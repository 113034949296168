import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { DeleteObjectModal } from 'app/components/shared/FormDialog/DeleteObjectModal';
import { SaveCloseModal } from 'app/components/shared/FormDialog/SaveCloseModal';
import { EditDescription } from 'app/components/shared/ProfilePages/EditGroupDescription';
import { EditTitle } from 'app/components/shared/ProfilePages/EditTitle';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch, useAppSelector } from 'app/store';
import { removeGuild, updateGuild } from 'app/store/guilds/actions';
import { selectGuildByID } from 'app/store/guilds/selectors';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { Breadcrumbs, Button, LoadingIndicator } from 'cfa-react-components';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './Guilds.scss';

export const GuildEditProfilePage = () => {
  const navigate = useNavigate();

  const { guildId } = useParams() as { guildId: string };
  const guild = useAppSelector(selectGuildByID(guildId));

  const [infoText, setInfoText] = useState(guild?.info);
  const [titleText, setTitleText] = useState(guild?.name);
  const dispatch = useAppDispatch();
  const allowGuildEdit: boolean = allowEditGuild();

  const saveUpdateGuild = () => {
    dispatch(updateGuild(guildId, titleText, infoText))
      .then(() => {
        closeInfo();
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.editGuild, guildId);
      });
  };

  const closeInfo = () => {
    if (guild) {
      navigate(`/guilds/${guild.id}`);
    }
  };

  const saveDeleteGuild = () => {
    dispatch(removeGuild(guildId))
      .then(() => {
        navigate('/guilds');
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.deleteGuild);
      });
  };

  return guild ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Guilds',
                onClick: function backToGuilds() {
                  navigate('/guilds');
                },
              },
              {
                label: guild?.name,
              },
            ]}
          />
          <div className="saveAndCloseButtons">
            <SaveCloseModal
              handleClose={closeInfo}
              handleSave={saveUpdateGuild}
            />
            <Button
              color="primary"
              onClick={saveUpdateGuild}
              size="md"
              variant="filled"
              style={{
                marginLeft: '5px',
              }}
              data-cy="save-guild"
            >
              Save
            </Button>
          </div>
        </div>
        <EditTitle titleText={titleText} setTitleText={setTitleText} />
        <EditDescription infoText={infoText} setInfoText={setInfoText} />

        <DeleteObjectModal
          type="Guild"
          itemName={guild.name}
          handleDelete={saveDeleteGuild}
          handleClose={function noRefCheck() {}}
          allowDelete={allowGuildEdit}
        />
      </div>
      <Footer />
    </>
  ) : (
    <LoadingIndicator variant={'page'}></LoadingIndicator>
  );
};
