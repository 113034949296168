import { RootState } from '..';
import { departmentAdapter } from './slice';

export const selectDepartmentReducerState = (store: RootState) =>
  store.department;

const departmentSelectors = departmentAdapter.getSelectors(
  selectDepartmentReducerState,
);

export const selectDepartments = (store: RootState) =>
  departmentSelectors.selectAll(store);

export const selectDepartmentById = (id: string) => (store: RootState) =>
  departmentSelectors.selectById(store, id);
