import { IconTrashFilled } from '@tabler/icons-react';
import { useLeadershipRoles } from 'app/store/roles/selectors';
import IdentityUser from 'app/types/IdentityUser';
import Role from 'app/types/Role';
import { Dropdown, Icon } from 'cfa-react-components';
import React, { useState } from 'react';
import UserDropdown from './UserDropdown';

export type InputRowProps = {
  tempUser?: IdentityUser | null;
  tempRole?: Role | null;
  onDelete: (index: number) => void;
  index: number;
  handleInputChange: (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => void;
  rolesList: Role[];
  originalLength?: number;
};

export const InputRow = ({
  tempUser,
  tempRole,
  onDelete,
  index,
  rolesList,
  handleInputChange,
  originalLength,
}: InputRowProps) => {
  const initialUserState = tempUser ? tempUser : null;
  const initialRoleState = tempRole ? tempRole : null;

  const [role, setRole] = React.useState<Role | null>(initialRoleState);
  const [user, setUser] = React.useState<IdentityUser | null>(initialUserState);
  const roleList = useLeadershipRoles();

  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }
  return (
    <div
      style={{
        marginTop: '.5rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        maxWidth: '100%',
      }}
    >
      <div
        style={{ marginRight: '1.5rem', marginLeft: '-0.2rem', width: '350px' }}
      >
        <UserDropdown
          user={user}
          setUser={setUser}
          index={index}
          handleInputChange={handleInputChange}
          originalLength={originalLength}
        />
      </div>
      <div style={{ marginLeft: '1.35rem', width: '350px' }}>
        <Dropdown
          label="Role"
          onChange={e => {
            setRole(e);
            handleInputChange(index, e, null, 'ROLE');
          }}
          options={rolesList}
          getOptionId={option => option.id}
          getOptionText={option => option.name}
          renderOption={option => option.name}
          placeholder="Select a role"
          value={role}
          fullWidth
          data-cy={`role-dropdown-${index}`}
        />
      </div>
      <div style={{ marginLeft: '.75rem', marginTop: '2.25rem' }}>
        <Icon
          onClick={() => {
            onDelete(index);
          }}
          icon={IconTrashFilled}
          style={{
            textAlign: 'center',
            cursor: 'pointer',
            justifyContent: 'center',
          }}
          height="1.75em"
          width="1.5em"
          data-cy={`delete-member-${index}`}
        />
      </div>
    </div>
  );
};

export default InputRow;
