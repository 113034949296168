import podsApi from 'app/services/PodsApi';
import { useAppSelector } from 'app/store';
import { selectUsers } from 'app/store/users/selectors';
import IdentityUser, { IdentityResponse } from 'app/types/IdentityUser';
import { useState } from 'react';

const paginateIdentity = async (
  fetcher: (lastId: string) => Promise<IdentityResponse>,
) => {
  let data: IdentityUser[] = [];
  let lastId: string = '';
  let hasMoreData: boolean = true;
  let counter = 0;
  while (hasMoreData && counter < 12) {
    const resp = await fetcher(lastId);

    if (resp.count === 0) {
      break;
    }
    data.push(...resp.Users);
    lastId = resp.lastId;
    hasMoreData = lastId ? true : false;
  }
  return data;
};
const getStaff = async () => {
  return paginateIdentity(podsApi.members.getStaff);
};
const getContractors = async () => {
  return paginateIdentity(podsApi.members.getContractors);
};

const useUsers = () => {
  const [data, setData] = useState<IdentityUser[]>([]);
  const users = useAppSelector(selectUsers);
  const fetchUsers = async (): Promise<{
    contractors: IdentityUser[];
    staff: IdentityUser[];
  }> => {
    const [contractors, staff] = await Promise.all([
      getContractors(),
      getStaff(),
    ]);
    return { contractors, staff };
  };

  return { data: users, isLoading: false };
};

export default useUsers;
