import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import { LeadershipTeam } from 'app/types/Leadership';

interface LeadershipState {
  // Key is department id
  entities: LeadershipTeam[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

export const leadershipAdapter = createEntityAdapter<LeadershipTeam>({
  selectId: a => a.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = leadershipAdapter.getInitialState({
  apiStatus: {
    loadLeadership: defaultApiStatus,
    updateLeadership: defaultApiStatus,
    addLeadership: defaultApiStatus,
    removeLeadership: defaultApiStatus,
  },
});

const leadershipSlice = createSlice({
  name: 'leadership',
  initialState,
  reducers: {
    addLeadership: leadershipAdapter.addOne,
    updateLeadership: leadershipAdapter.updateOne,
    createLeadershipMember: leadershipAdapter.updateOne,
    updateLeadershipMember: leadershipAdapter.updateOne,
    deleteLeadership: leadershipAdapter.removeOne,
    deleteLeadershipMember: leadershipAdapter.updateOne,
    reloadLeadership: leadershipAdapter.setOne,
    setLeadershipRequest: state => {
      state.apiStatus.loadLeadership.state = ApiState.LOADING;
      state.apiStatus.loadLeadership.error = null;
    },
    setLeadershipSuccess: (state, action: PayloadAction<LeadershipTeam[]>) => {
      state.apiStatus.loadLeadership.state = ApiState.IDLE;
      state.apiStatus.loadLeadership.error = null;
      leadershipAdapter.setAll(state, action);
    },
    setLeadershipError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadLeadership.state = ApiState.ERROR;
      state.apiStatus.loadLeadership.error = action.payload;
    },
  },
});

export const {
  addLeadership,
  updateLeadership,
  createLeadershipMember,
  updateLeadershipMember,
  deleteLeadership,
  deleteLeadershipMember,
  setLeadershipRequest,
  setLeadershipSuccess,
  setLeadershipError,
  reloadLeadership,
} = leadershipSlice.actions;

export default leadershipSlice;
