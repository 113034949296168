import Department from 'app/types/Department';
import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { Accordion, AccordionItem, Typography } from 'cfa-react-components';
import { ConsultantAccordionList } from './ConsultantAccordionList';

export type ConsultantAccordionProps = {
  currentDept: string;
  subDeptMap: Map<string, Department>;
  roleList: Role[];
  consultantList: Member[];
};

export const CounsultantAccordionMenu = ({
  currentDept,
  subDeptMap,
  roleList,
  consultantList,
}: ConsultantAccordionProps) => {
  return (
    <Accordion
      style={{
        paddingLeft: '50px',
        paddingRight: '50px',
      }}
    >
      <AccordionItem
        itemKey={subDeptMap.get(currentDept)?.name!}
        header={
          <Typography
            variant="overline1"
            color="primary"
            align="center"
            data-cy="consultants"
          >
            Subdepartment Consultants
          </Typography>
        }
      >
        <ConsultantAccordionList
          currentDept={currentDept}
          subDeptMap={subDeptMap}
          consultantList={consultantList}
          roleList={roleList}
        />
      </AccordionItem>
    </Accordion>
  );
};
export default CounsultantAccordionMenu;
