import Navigation from 'app/components/Navigation/Navigation';
import EmployeePicture from 'app/components/shared/EmployeePicture';
import LeadershipCard from 'app/components/shared/LeadershipCard/LeadershipCard';
import PodCard from 'app/components/shared/PodCard/PodCard';
import ApiState from 'app/constants/ApiState';
import memberApi from 'app/services/MembersApi';
import { useAppSelector } from 'app/store';
import { selectDepartments } from 'app/store/dept/selectors';
import { selectLeadership } from 'app/store/leadership/selectors';
import { selectPods } from 'app/store/pods/selectors';
import { useLeadershipRoles, usePodRoles } from 'app/store/roles/selectors';
import {
  selectLoadUserStatus,
  selectUserById,
} from 'app/store/users/selectors';
import Department from 'app/types/Department';
import LeadershipTeam from 'app/types/Leadership';
import Member from 'app/types/Member';
import MemberType from 'app/types/MemberType';
import Pod from 'app/types/Pod';
import {
  Card,
  Divider,
  List,
  ListItem,
  LoadingIndicator,
  Typography,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PageNotFound from '../PageNotFound/PageNotFound';
import './ProfilePage.scss';

export const ProfilePage = () => {
  const { profileId } = useParams() as { profileId: string };
  const userStatus = useAppSelector(selectLoadUserStatus);
  const deptList: Department[] = useAppSelector(selectDepartments);

  const user = useAppSelector(selectUserById(profileId));
  const [involvements, setInvolvements] = useState<Member[]>([]);
  const pods = useAppSelector(selectPods);
  const leadershipTeams = useAppSelector(selectLeadership);
  const newRoleList = usePodRoles();
  const newLeadershipRoleList = useLeadershipRoles();
  const [newPods, setNewPods] = useState<Pod[]>([]);
  const [newLeadershipTeams, setNewLeadershipTeams] = useState<
    LeadershipTeam[]
  >([]);
  const [loading, setLoading] = useState(true);

  const sortInvolvements = (involvements: Member[]) => {
    let filteredPods: Pod[] = [];
    let filteredLeadership: LeadershipTeam[] = [];

    involvements?.forEach(involvement => {
      if (involvement.type == MemberType.PODS) {
        const pod = pods.find(p => p.id === involvement.groupId);
        if (pod) {
          filteredPods.push(pod);
        }
      } else if (involvement.type == MemberType.LEADERSHIP) {
        const leadership = leadershipTeams.find(
          lt => lt.id === involvement.groupId,
        );
        if (leadership) {
          filteredLeadership.push(leadership);
        }
      }
    });

    return { filteredPods, filteredLeadership };
  };

  useEffect(() => {
    if (user != undefined) {
      memberApi.members
        .getMember(profileId)
        .then(data => {
          setInvolvements(data);
        })
        .catch(e => {
          setInvolvements([]);
        });
    }
  }, [user, profileId]);

  useEffect(() => {
    const { filteredPods, filteredLeadership } = sortInvolvements(involvements);
    setNewPods(filteredPods);
    setNewLeadershipTeams(filteredLeadership);
    setLoading(false);
  }, [involvements]);

  if (userStatus.state == ApiState.LOADING) {
    return (
      <div className="loading">
        <LoadingIndicator variant={'page'}></LoadingIndicator>
      </div>
    );
  } else if (userStatus.state == ApiState.IDLE && user) {
    return (
      <>
        <div>
          <Navigation />
        </div>
        <div className="main-page">
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="h2"
              color="primary"
              style={{
                textAlign: 'center',
                paddingBottom: '20px',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              {user?.displayName}
            </Typography>
            <EmployeePicture
              user={user!}
              imgWidth={'200px'}
              imgHeight={'200px'}
            />
            <Typography
              variant="h2"
              color="primary"
              style={{
                textAlign: 'center',
                paddingBottom: '20px',
                marginTop: '20px',
                marginBottom: '5px',
              }}
            >
              Current Involvements
            </Typography>
            {loading && <LoadingIndicator variant="inline" />}
            {!loading && (
              <div
                className="current-involvements"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                {loading && <LoadingIndicator variant="inline" size="md" />}
                {newPods.length > 0 ? (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      Pods
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      variant="fullLength"
                      style={{
                        width: '200px',
                      }}
                    />
                    <List>
                      {newPods.map(pod => (
                        <ListItem
                          key={pod.id}
                          style={{
                            minWidth: '280px',
                          }}
                        >
                          <PodCard rolesList={newRoleList} pod={pod} />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                ) : (
                  <></>
                )}
                {!loading && newLeadershipTeams.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingRight: '30px',
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="secondary"
                      style={{
                        textAlign: 'center',
                        marginTop: '20px',
                        marginBottom: '5px',
                      }}
                    >
                      Leadership Teams
                    </Typography>
                    <Divider
                      orientation="horizontal"
                      variant="fullLength"
                      style={{
                        width: '200px',
                      }}
                    />
                    <List>
                      {newLeadershipTeams.map(team => (
                        <ListItem
                          key={team.id}
                          style={{
                            minWidth: '280px',
                          }}
                        >
                          <LeadershipCard
                            onProfile
                            rolesList={newLeadershipRoleList}
                            deptList={deptList}
                            leadership={team}
                            leadershipMembers={team.members ?? []}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
      </>
    );
  } else {
    return <PageNotFound></PageNotFound>;
  }
};

export default ProfilePage;
