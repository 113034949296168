import { ActionsVertical } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import {
  removeConsultant,
  updateConsultant,
} from 'app/store/consultants/actions';
import Department from 'app/types/Department';
import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { allowAddConsultant } from 'app/utils/hasPermissions/allowFeature';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { DeleteObjectModal } from '../FormDialog/DeleteObjectModal';

export type EditConsultantProps = {
  currentDepartment: Department;
  rolesList: Role[];
  consultant: Member;
};

export const EditConsultant = ({
  currentDepartment,
  rolesList,
  consultant,
}: EditConsultantProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const allowConsultantEdit: boolean = allowAddConsultant();

  useEffect(() => {
    let currentRole =
      rolesList.find(role => role.id === consultant.roleId) || null;
    setRole(currentRole);
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveClose = () => {
    dispatch(updateConsultant(consultant, currentDepartment!, role!))
      .then(() => {
        setRole(null);
        setOpen(false);
      })
      .catch(e => {
        displayErrorToast(e.message, ACTIONS.editConsultant, consultant.userId);
      });
  };

  const handleDelete = () => {
    dispatch(removeConsultant(consultant, currentDepartment!))
      .then(() => {
        setRole(null);
        setOpen(false);
      })
      .catch(e => {
        displayErrorToast(
          e.message,
          ACTIONS.deleteConsultant,
          consultant.userId,
        );
      });
  };

  return (
    <>
      <IconButton
        aria-label="Consultant Actions"
        size="md"
        onClick={handleClickOpen}
      >
        <Icon icon={ActionsVertical} />
      </IconButton>
      <Modal
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <>
          <ModalHeader>Edit Info for {consultant.name} </ModalHeader>
          <ModalBody>
            Edit the fields below or delete the consultant.
            <Container style={{ textAlign: 'left' }}>
              <>
                <Row style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                  <Col sm={6}>
                    <Dropdown<string>
                      label="Consultant"
                      value={consultant.name}
                      options={[]}
                      onChange={function noRefCheck() {}}
                      fullWidth
                      readOnly
                    />
                  </Col>
                  <Col sm={6}>
                    <Dropdown<Role>
                      label="Position"
                      options={rolesList}
                      value={role}
                      onChange={e => setRole(e)}
                      placeholder="Select a role"
                      getOptionId={option => option.id}
                      getOptionText={option => option.name}
                      renderOption={option => option.name}
                      fullWidth
                      required
                    />
                  </Col>
                </Row>
                <Row style={{ marginBottom: '1rem', marginTop: '1.5rem' }} />
              </>
            </Container>
          </ModalBody>
          <ModalFooter>
            <DeleteObjectModal
              type="Consultant"
              itemName={consultant.name}
              handleClose={handleClose}
              handleDelete={handleDelete}
              allowDelete={allowConsultantEdit}
            />
            <Button
              size="lg"
              onClick={handleSaveClose}
              variant="filled"
              disabled={!role}
            >
              Save Changes
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default EditConsultant;
