import { RootState } from '..';
import { guildsAdapter } from './slice';

export const selectGuildReducerState = (store: RootState) => store.guilds;

const guildSelectors = guildsAdapter.getSelectors(selectGuildReducerState);

export const selectGuilds = (store: RootState) =>
  guildSelectors.selectAll(store);

export const selectGuildByID = (id: string) => (store: RootState) =>
  guildSelectors.selectById(store, id);
