import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';
import { ConsultantsAdapter } from './slice';

export const selectConsultantReducerState = (store: RootState) =>
  store.consultants;

const consultantSelectors = ConsultantsAdapter.getSelectors(
  selectConsultantReducerState,
);

export const selectConsultants = (store: RootState) =>
  consultantSelectors.selectAll(store);

export const selectConsultantsByDeptId1 =
  (deptId: string) => (store: RootState) =>
    consultantSelectors
      .selectAll(store)
      .filter(a => a.groupId.localeCompare(deptId) === 0);

export const selectConsultantsByDeptId = (deptId: string) =>
  createSelector(
    [selectConsultantsByDeptId1(deptId)],
    consultants => consultants,
  );

export const selectConsultantById =
  (employeeId: string) => (store: RootState) =>
    consultantSelectors.selectById(store, employeeId);
