import React from 'react';
import './Footer.scss';

//Footer component found at the bottom of all pages
class Footer extends React.Component {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <div className="footerContainer">
        {/* This below section creates a container for which the footer text sits in. It has been styled primarily for its size. */}
        <div className="footer">
          <p className="footer-info" data-cy="footer">
            © {currentYear} Chick-fil-A, Inc. All rights reserved.
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
