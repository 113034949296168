import { OKTA_STORAGE } from 'app/constants/AppPermissions';

const getOktaAccessToken = () => {
  const oktaCookie = localStorage.getItem(OKTA_STORAGE);
  let accessToken = '';

  if (oktaCookie) {
    const token = JSON.parse(oktaCookie);
    accessToken = token?.accessToken?.accessToken;
  }

  return accessToken;
};

export { getOktaAccessToken };
