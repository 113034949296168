import { createBrowserHistory } from 'history';

// Creates a browser history object
// The created object can be used to interact with the browser's session history, allowing the application to navigate between different pages.
// The basename property is set to an empty string, which means that the application is not served from a subdirectory, and the history object
// should operate on the current URL path.

export default createBrowserHistory({
  basename: '',
});
