import Member from 'app/types/Member';
import { Role } from 'app/types/Role';
import { allowAddConsultant } from 'app/utils/hasPermissions/allowFeature';
import { List } from 'cfa-react-components';
import AddConsultantListItem from './AddConsultantListItem';
import { ConsultantAccordionListItem } from './ConsultantAccordionListItem';
import { ConsultantAccordionProps } from './ConsultantAccordionMenu';

export type ConsultantAccordionListProps = {
  consultantList: Member[];
  roleList: Role[];
} & ConsultantAccordionProps;

export const ConsultantAccordionList = ({
  currentDept,
  subDeptMap,
  consultantList,
  roleList,
}: ConsultantAccordionListProps) => {
  const sortedConsultants = consultantList.sort((a, b) => {
    const roleA = roleList.find(role => role.id === a.roleId)?.name || '';
    const roleB = roleList.find(role => role.id === b.roleId)?.name || '';
    return roleA.localeCompare(roleB);
  });
  const allowAddingConsultant: boolean = allowAddConsultant();
  return (
    <>
      <List
        style={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 50%)',
        }}
      >
        {sortedConsultants.map(consultant => (
          <ConsultantAccordionListItem
            key={consultant.userId}
            consultant={consultant}
            roleList={roleList}
            currentDept={subDeptMap.get(currentDept)!}
          />
        ))}
        <AddConsultantListItem
          currentDepartment={subDeptMap.get(currentDept)!}
          rolesList={roleList}
          allowAddConsultant={allowAddingConsultant}
        />
      </List>
    </>
  );
};

export default ConsultantAccordionList;
