import { ADMIN } from 'app/constants/AppPermissions';
import { getOktaToken } from '../getOktaToken';

export interface OktaToken {
  idToken: {
    claims?: {
      cfa_perms?: {
        [key: string]: {
          [key: string]: string[];
        };
      };
    };
  };
  accessToken: {
    accessToken: string;
    expiresAt: number;
    tokenType: string;
    scopes: string[];
    authorizeUrl: string;
    userinfoUrl: string;
  };
}

export const hasPermission = (
  /** appName - name of the app like FCR_PLATFORM and use a constant. */
  appName: string,
  /** permissions required for access: ex. "PODS_ADMIN", "SG_ADMIN", "DTT_LOGIN" */
  permissions: string[],
  /** location - location number */
  location?: string,
): boolean => {
  const OktaToken: OktaToken | undefined = getOktaToken();
  const cfaPerms = OktaToken?.idToken?.claims?.cfa_perms;
  const userRoles: { [key: string]: string[] } =
    cfaPerms && cfaPerms[appName] ? cfaPerms[appName] : {};
  const userRolesKeys: string[] = Object.keys(userRoles);

  if (userRolesKeys.length > 0) {
    if (userRolesKeys.includes(ADMIN)) {
      return true;
    }

    for (const userPermission of userRolesKeys) {
      if (permissions.includes(userPermission)) {
        return true;
      }
    }
  }

  return false;
};
