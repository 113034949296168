import { Check } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createDepartment } from 'app/store/dept/actions';
import { BaseDepartment } from 'app/types/Department';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Icon,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
} from 'cfa-react-components';
import * as React from 'react';
import { useState } from 'react';

export const CreateDept = () => {
  const dispatch = useAppDispatch();

  const [deptName, setDeptName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [deptAlias, setDeptAlias] = useState('');

  // The input field for the new sub-department name is cleared when the user closes handle without creating a new sub-department
  const handleClose = () => {
    setOpen(false);
    setDeptName('');
    setDeptAlias('');
  };

  // Closes the handle, logs the sub-department name to the console, and saves the sub-department name to the component's state.
  const handleSaveClose = () => {
    const d = {
      name: deptName,
      alias: deptAlias.toUpperCase(),
    } as BaseDepartment;
    dispatch(createDepartment(d.name, d.alias))
      .then(() => {
        setOpen(false);
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.createDepartment);
      });
  };

  return (
    <>
      <Button
        className="create_btn"
        size="md"
        variant="text"
        color="primary"
        onClick={e => setOpen(true)}
        style={{
          marginLeft: '20px',
          marginBottom: '10px',
          fontSize: '17px',
        }}
      >
        <React.Fragment key=".0">
          <Icon icon={Check} data-cy="create-dept-btn" />
          Create New
        </React.Fragment>
      </Button>

      <Modal
        data-cy="cancel-dept"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <React.Fragment key=".0">
          <ModalHeader>Create New Sub-department</ModalHeader>
          <ModalBody>
            To create a new sub-department, enter a new sub-department name.
            <Container style={{ textAlign: 'left', padding: 10 }}>
              <Col style={{ marginTop: 10 }}>
                <TextField
                  label="Department"
                  required
                  onChange={e => setDeptName(e.target.value)}
                  defaultValue=""
                  placeholder="Enter new Department name"
                  fullWidth
                  data-cy="dept-in"
                />
              </Col>
              <Col style={{ marginTop: 10 }}>
                <TextField
                  label="Department Alias"
                  helperText="Example: DTT for Digital Transformation & Technology"
                  required
                  onChange={e => setDeptAlias(e.target.value)}
                  placeholder="Enter department alias"
                  data-cy="dept-alias-in"
                />
              </Col>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              onClick={handleSaveClose}
              variant="filled"
              disabled={!(deptName && deptAlias)}
              data-cy="post-dept-btn"
            >
              Create Sub-department
            </Button>
          </ModalFooter>
        </React.Fragment>
      </Modal>
    </>
  );
};
export default CreateDept;
