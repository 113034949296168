import { RootState } from '..';
import { identityAdapter } from './slice';

export const selectIdentityReducerState = (store: RootState) => store.identity;

const identitySelectors = identityAdapter.getSelectors();
export const selectUsers = (store: RootState) =>
  identitySelectors.selectAll(store.identity);

export const selectUserById = (userId: string) => (store: RootState) =>
  identitySelectors.selectById(store.identity, userId);

export const selectLoadUserStatus = (store: RootState) =>
  store.identity.apiStatus.loadUsers;
