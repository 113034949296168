import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import { Department } from 'app/types/Department';

interface DepartmentState {
  entities: Department[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}
// type DepartmentMap = Map<string,Department>
// export const initialState: DepartmentState = {
//   entities: [],
//   status: "idle",
// };
const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};
export const departmentAdapter = createEntityAdapter<Department>({
  selectId: a => a.id,
  sortComparer: (a, b) => a.alias.localeCompare(b.alias),
});
const initialState = departmentAdapter.getInitialState({
  apiStatus: {
    loadDepartments: defaultApiStatus,
    updateDepartment: defaultApiStatus,
    addDepartment: defaultApiStatus,
    removeDepartment: defaultApiStatus,
  },
});

const deptSlice = createSlice({
  name: 'department',
  initialState,
  reducers: {
    addDepartment: departmentAdapter.addOne,
    updateDepartment: departmentAdapter.updateOne,
    deleteDepartment: departmentAdapter.removeOne,
    setDepartmentsRequest: state => {
      state.apiStatus.loadDepartments.state = ApiState.LOADING;
      state.apiStatus.loadDepartments.error = null;
    },
    setDepartmentsSuccess: (state, action: PayloadAction<Department[]>) => {
      state.apiStatus.loadDepartments.state = ApiState.IDLE;
      state.apiStatus.loadDepartments.error = null;
      departmentAdapter.setAll(state, action);
    },
    setDepartmentsError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadDepartments.state = ApiState.ERROR;
      state.apiStatus.loadDepartments.error = action.payload;
    },
  },
});

export const {
  addDepartment,
  updateDepartment,
  deleteDepartment,
  setDepartmentsRequest,
  setDepartmentsSuccess,
  setDepartmentsError,
} = deptSlice.actions;

export default deptSlice;
