import { useAppSelector } from 'app/store';
import { selectGuilds } from 'app/store/guilds/selectors';
import Guild from 'app/types/Guild';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const GuildSearchSection = ({ search }: SearchSectionProps) => {
  const navigate = useNavigate();

  const clickGuild = (id: string) => {
    navigate(`/guilds/${id}`);
  };

  const guilds = useAppSelector(selectGuilds);
  const [guildResults, setGuildResults] = useState<Guild[]>([] as Guild[]);
  const [guildCollapse, setGuildCollapse] = useState(true);

  useEffect(() => {
    const filteredGuilds = guilds.filter(guild =>
      guild.name.toLowerCase().includes(search.toLowerCase()),
    );
    setGuildResults(filteredGuilds);
  }, [search]);

  return (
    <>
      <SearchHeader
        title="Guilds"
        size={guildResults.length}
        collapse={guildCollapse}
        setCollapse={setGuildCollapse}
      />
      <LineBreak />
      <Results<Guild>
        results={guildResults}
        collapse={guildCollapse}
        navigation={clickGuild}
      />
    </>
  );
};
