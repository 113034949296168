export const DTT_PODS = 'DTT_PODS';

export const ADMIN = 'ADMIN';

export const COUNCILS_ADMIN = 'COUNCILS_ADMIN';

export const DTT_LOGIN = 'DTT_LOGIN';

export const GUILDS_ADMIN = 'GUILDS_ADMIN';

export const LOGIN = 'LOGIN';

export const PODS_ADMIN = 'PODS_ADMIN';

export const SG_ADMIN = 'SG_ADMIN';

export const OKTA_STORAGE = 'okta-token-storage';
