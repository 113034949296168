import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';

import { useAppDispatch, useAppSelector } from 'app/store';
import { initializeApp } from 'app/store/app/actions';
import { selectIsInitialized } from 'app/store/app/selectors';
import AppConstants from 'app/utils/AppConstants';
import { PropsWithChildren, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';

const InitializeApp = ({ children }: PropsWithChildren) => {
  const isInitialized = useAppSelector(selectIsInitialized);
  const dispatch = useAppDispatch();

  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin,
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    if (authState.isAuthenticated && !isInitialized) {
      dispatch(initializeApp());
    }
  }, [!!authState, authState?.isAuthenticated]);

  return (
    <>
      <Toaster
        toastOptions={{
          error: { duration: Infinity },
          success: { duration: AppConstants.TOAST_DURATION },
        }}
      />
      {children}
    </>
  );
};

export default InitializeApp;
