import { Plus, UserPlus } from '@cfa-icons/system';
import { ACTIONS } from 'app/constants/UserMessaging';
import { useAppDispatch } from 'app/store';
import { createConsultant } from 'app/store/consultants/actions';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Role from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import {
  Button,
  Col,
  Container,
  Divider,
  Dropdown,
  Icon,
  ListItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Typography,
} from 'cfa-react-components';
import { useState } from 'react';
import UserDropdown from '../UserDropdown';

export type AddLeadershipListItemProps = {
  rolesList: Role[];
  currentDepartment: Department;
  allowAddConsultant: boolean;
};

export const AddConsultantListItem = ({
  rolesList,
  currentDepartment,
  allowAddConsultant,
}: AddLeadershipListItemProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState<Role | null>(null);
  const [user, setUser] = useState<IdentityUser | null>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRole(null);
    setUser(null);
  };

  const handleSaveClose = () => {
    dispatch(createConsultant(user!, currentDepartment, role!))
      .then(() => {
        handleClose();
      })
      .catch(error => {
        displayErrorToast(error.message, ACTIONS.createConsultant, user!.GUID);
      });
  };

  return (
    <>
      {allowAddConsultant && (
        <ListItem
          aria-label="Consultant Actions"
          onClick={handleClickOpen}
          disabled={!allowAddConsultant}
          style={{
            marginTop: '10px',
            paddingLeft: '20px',
          }}
          startItem={
            <Icon icon={UserPlus} size="md" style={{ marginBottom: '8px' }} />
          }
          endItem={
            <Icon icon={Plus} size="md" style={{ marginRight: '10px' }} />
          }
        >
          <Typography
            variant="body1"
            color="secondary"
            fontWeight="bold"
            align="left"
            style={{
              paddingTop: '30px',
              paddingLeft: '27px',
              marginTop: '-35px',
            }}
          >
            Add Consultant
          </Typography>
          <Divider
            variant="fullLength"
            style={{
              paddingTop: '10px',
              marginLeft: '14px',
              width: '95%',
            }}
          />
        </ListItem>
      )}
      <Modal
        data-cy="close-add-consultant"
        show={open}
        onClose={handleClose}
        size="md"
        scrollMode="modal-body"
      >
        <>
          <ModalHeader> Add New Consultant </ModalHeader>
          <ModalBody>
            Fill out the required fields to add a consulant to this
            subdepartment.
            <Container style={{ textAlign: 'left' }}>
              <>
                <Row style={{ marginBottom: '1rem', marginTop: '2rem' }}>
                  <Col sm={6}>
                    <UserDropdown user={user} setUser={setUser} />
                  </Col>
                  <Col sm={6}>
                    <Dropdown<Role>
                      label="Position"
                      options={rolesList}
                      value={role}
                      onChange={e => setRole(e)}
                      placeholder="Select a role"
                      getOptionId={option => option.id}
                      getOptionText={option => option.name}
                      renderOption={option => option.name}
                      fullWidth
                      required
                    />
                  </Col>
                </Row>
                <Row
                  style={{
                    marginBottom: '1rem',
                    marginTop: '1.5rem',
                  }}
                ></Row>
              </>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              onClick={handleSaveClose}
              variant="filled"
              disabled={!user || !role}
            >
              Add Consultant
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
};

export default AddConsultantListItem;
