import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import { Guild } from 'app/types/Guild';

interface GuildsState {
  entities: Guild[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

export const guildsAdapter = createEntityAdapter<Guild>({
  selectId: a => a.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = guildsAdapter.getInitialState({
  apiStatus: {
    loadGuilds: defaultApiStatus,
    updateGuild: defaultApiStatus,
    addGuild: defaultApiStatus,
    removeGuild: defaultApiStatus,
  },
});

const guildsSlice = createSlice({
  name: 'guilds',
  initialState,
  reducers: {
    addGuild: guildsAdapter.addOne,
    updateGuild: guildsAdapter.updateOne,
    deleteGuild: guildsAdapter.removeOne,
    setGuildsRequest: state => {
      state.apiStatus.loadGuilds.state = ApiState.LOADING;
      state.apiStatus.loadGuilds.error = null;
    },
    setGuildsSuccess: (state, action: PayloadAction<Guild[]>) => {
      state.apiStatus.loadGuilds.state = ApiState.IDLE;
      state.apiStatus.loadGuilds.error = null;
      guildsAdapter.setAll(state, action);
    },
    setGuildsError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadGuilds.state = ApiState.ERROR;
      state.apiStatus.loadGuilds.error = action.payload;
    },
  },
});

export const {
  addGuild,
  updateGuild,
  deleteGuild,
  setGuildsError,
  setGuildsRequest,
  setGuildsSuccess,
} = guildsSlice.actions;

export default guildsSlice;
