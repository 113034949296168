import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const membersApiClient = createApiClient(AppConstants.URL.MEMBERS);
const memberApi = {
  members: {
    getMembersByType: (groupType: MemberType) =>
      membersApiClient.get(`members?group_type=${groupType}`).json<Member[]>(),
    getMembersByGroup: (groupType: MemberType, groupId: string) =>
      membersApiClient.get(`members/${groupType}/${groupId}`).json<Member[]>(),
    createMember: (
      data: {
        userId: string;
        groupId: string;
        type: MemberType;
        name: string;
        isContingent: boolean;
        status: MemberStatus;
      },
      roleId?: string,
    ) =>
      membersApiClient
        .post(`members`, {
          json: {
            userId: data.userId,
            groupId: data.groupId,
            type: data.type,
            name: data.name,
            isContingent: data.isContingent,
            status: data.status,
            roleId: roleId,
          },
        })
        .json<Member>(),
    deleteMember: (groupId: string, userId: string) =>
      membersApiClient.delete(`members/${groupId}/${userId}`).json(),
    getMember: (userId: string) =>
      membersApiClient.get(`members/${userId}`).json<Member[]>(),
    updateMember: (
      userId: string,
      groupId: string,
      type: MemberType,
      roleId?: string,
      startDate?: Date,
      endDate?: Date,
      status?: MemberStatus,
    ) => {
      const params: {
        groupId: string;
        type: string;
        roleId?: string;
        startDate?: Date;
        endDate?: Date;
        status?: MemberStatus;
      } = {
        groupId: groupId,
        type: type,
      };
      if (roleId) {
        params.roleId = roleId;
      }
      if (startDate) {
        params.startDate = startDate;
      }
      if (endDate) {
        params.endDate = endDate;
      }
      if (status) {
        params.status = status;
      }
      return membersApiClient
        .put(`members/${userId}`, { json: params })
        .json<Member>();
    },
  },
};

export default memberApi;
