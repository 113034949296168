import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  TextField,
  Typography,
} from 'cfa-react-components';
import { useId, useState } from 'react';
import './DeleteObjectModal.scss';

export type DeleteObjectModalProps = {
  itemName: string;
  allowDelete?: boolean;
  handleDelete: () => void;
  handleClose: () => void;
  type: string;
};

export const DeleteObjectModal = ({
  itemName,
  allowDelete,
  handleDelete,
  handleClose,
  type,
}: DeleteObjectModalProps) => {
  const [open, setOpen] = useState(false);
  const [deleteText, setDeleteText] = useState('');
  const id = useId();

  const handleDeleteOpen = (e: boolean) => {
    setOpen(e);
    if (e) {
      setDeleteText('');
    }
  };

  const handleCloseModal = () => {
    handleClose();
  };

  const getStyle = () => {
    if (type === 'Guild' || type === 'Leadership Team' || type === 'Pod') {
      return 'filled';
    }
    return 'outlined';
  };

  const style = getStyle();

  return (
    <>
      <Button
        className={
          type === 'Guild' || type === 'Leadership Team' || type === 'Pod'
            ? 'corner-delete'
            : 'modal-delete'
        }
        onClick={event => {
          event.stopPropagation();
          handleDeleteOpen(true);
        }}
        variant={style}
        disabled={!allowDelete}
      >
        Delete {type}
      </Button>
      <Modal
        show={open}
        onClose={() => {
          handleDeleteOpen(false);
          handleCloseModal();
        }}
        id={id}
        scrollMode="modal-body"
      >
        <ModalHeader>Delete "{itemName}"</ModalHeader>
        <ModalBody>
          <Typography>
            Are you sure you want to delete "{itemName}" {type}?
          </Typography>

          <TextField
            placeholder="delete permanently"
            textAlign="start"
            helperText='To confirm, please type "delete permanently" in the box below '
            onChange={event => setDeleteText(event.target.value)}
            fullWidth
            margin
            cols={0}
            rows={0}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              handleDelete();
              setOpen(false);
            }}
            variant="outlined"
            disabled={deleteText !== 'delete permanently'}
          >
            Delete {type}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
