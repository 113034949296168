import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { Divider, Typography } from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ConsultantRoleSearchSection } from './ConsultantRoleSearchSection';
import { GuildSearchSection } from './GuildsSearchSection';
import { LeadershipSearchSection } from './LeadershipSearchSection';
import { PeopleSearchSection } from './PeopleSearchSection';
import { PodSearchSection } from './PodSearchSection';
import { RoleSearchSection } from './RoleSearchSection';
import './SearchPage.scss';
import SearchSectionWrapper from './SearchSectionStyling';

export const SearchPage = () => {
  const [search, setSearch] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const q = params.get('q');
    if (q) {
      setSearch(q);
    }
  }, [location]);

  return (
    <>
      <Navigation />
      <div className="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Search Results
          </Typography>
        </div>

        <div>
          <Typography
            variant="h2"
            fontWeight="bold"
            align="left"
            gutterBottom
            style={{
              marginLeft: '80px',
              color: '#5B6770',
            }}
          >
            You searched for "{search}"
          </Typography>
          <Divider orientation="horizontal" variant="middle" inset={80} />
        </div>
        <br></br>
        <SearchSectionWrapper>
          <PeopleSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <PodSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <LeadershipSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <GuildSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <RoleSearchSection search={search} />
        </SearchSectionWrapper>
        <SearchSectionWrapper>
          <ConsultantRoleSearchSection search={search} />
        </SearchSectionWrapper>
      </div>
      <Footer />
    </>
  );
};

export default SearchPage;

export type SearchSectionProps = {
  search: string;
};

function collapseList<T>(list: T[]): T[] {
  return list.slice(0, 4);
}

type ResultsProps<T> = {
  results: T[];
  collapse: boolean;
  navigation?: (id: string) => void;
};

type SharedProps = {
  id?: string;
  name?: string;
  userId?: string;
  displayName?: string;
};

export const Results = <T extends SharedProps>({
  results,
  collapse,
  navigation,
}: ResultsProps<T>) => {
  const checkUserNavigate = (item: T) => {
    if (navigation) {
      if (item.userId) {
        navigation(item.userId);
      } else {
        navigation(item.id!);
      }
    }
  };

  return (
    <>
      <div className="search-results">
        {collapse
          ? collapseList<T>(results).map(item => {
              return (
                <Typography
                  onClick={() => checkUserNavigate(item)}
                  key={item.id ?? item.userId}
                  variant="h4"
                  color="secondary"
                  fontWeight="medium"
                  align="left"
                  style={{
                    marginLeft: '80px',
                    cursor: 'pointer',
                  }}
                >
                  {item.name}
                </Typography>
              );
            })
          : results.map(item => {
              return (
                <Typography
                  onClick={() => checkUserNavigate(item)}
                  key={item.id ?? item.userId}
                  variant="h4"
                  color="secondary"
                  fontWeight="medium"
                  align="left"
                  style={{
                    marginLeft: '80px',
                    cursor: 'pointer',
                  }}
                >
                  {item.name ?? item.displayName}
                </Typography>
              );
            })}
        <div>
          {results.length === 0 ? (
            <Typography
              variant="h4"
              fontWeight="medium"
              align="left"
              style={{
                color: 'grey',
                marginLeft: '80px',
              }}
            >
              No Results
            </Typography>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

type SearchHeaderProps = {
  title: string;
  size: number;
  collapse: boolean;
  setCollapse: (collapse: boolean) => void;
};
export const SearchHeader = ({
  title,
  size,
  collapse,
  setCollapse,
}: SearchHeaderProps) => {
  return (
    <>
      <div className="search-result-header">
        <Typography
          variant="subtitle2"
          color="primary"
          fontWeight="bold"
          align="left"
        >
          {title}
        </Typography>
        {size > 4 ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
              onClick={() => setCollapse(!collapse)}
            >
              <Typography
                variant="caption1"
                color="secondary"
                fontWeight="medium"
                align="right"
                style={{
                  marginTop: '4px',
                  marginRight: '4px',
                  cursor: 'pointer',
                }}
              >
                {collapse ? 'View All' : 'Collapse'}
              </Typography>
              <Typography
                variant="body2"
                fontWeight="regular"
                align="right"
                style={{
                  color: '#80878C',
                  marginBottom: '-12px',
                }}
              >
                ({size}) Results
              </Typography>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export const LineBreak = () => {
  return (
    <hr
      style={{
        border: 'none',
        height: '1px', // Adjust the thickness of the line
        background: 'linear-gradient(to right, red 25%, grey 25%)', // Gradient from red to grey
        marginLeft: '80px',
        marginRight: '80px',
      }}
    />
  );
};
