import { Pencil } from '@cfa-icons/system';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import {
  Icon,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import { useRef, useState } from 'react';

// Shared Component for editing the description of a page on a group Profile page
type EditDescriptionProps = {
  infoText?: string;
  setInfoText: (text: string) => void;
};

export const EditDescription = ({
  infoText,
  setInfoText,
}: EditDescriptionProps) => {
  const [infoEditMode, setInfoEditMode] = useState(false);
  const infoRef = useRef<TextFieldType>(null);
  useRevertEdit(infoRef, setInfoEditMode, 'infoPencil');

  return (
    <div className="body-section">
      {infoEditMode ? (
        <TextField
          multiline
          fullWidth
          rows={0}
          value={infoText}
          onChange={e => setInfoText(e.target.value)}
          placeholder="Add a description"
          ref={infoRef}
          style={{
            marginLeft: '30px',
            marginRight: '30px',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
          data-cy="info-edit-box"
        />
      ) : (
        <Typography
          variant="body1"
          color="secondary"
          fontWeight="medium"
          align="center"
          gutterBottom
          onClick={() => setInfoEditMode(true)}
          ref={infoRef}
          style={{
            paddingLeft: '12px',
            marginTop: '0px',
            marginRight: '20px',
            overflow: 'scroll',
            overflowX: 'hidden',
            overflowY: 'auto',
            color: infoText ? 'inherit' : 'grey',
            fontStyle: infoText ? 'inherit' : 'italic',
          }}
          data-cy="info-no-edit"
        >
          {infoText ? infoText : 'Add a description'}
        </Typography>
      )}
      <Icon
        className="infoPencil"
        icon={Pencil}
        size="md"
        color="grey"
        onClick={() => setInfoEditMode(!infoEditMode)}
        style={{ position: 'relative', top: '-5px', cursor: 'pointer' }}
        data-cy="edit-pencil-info"
      />
    </div>
  );
};
