import departmentsApi from 'app/services/DepartmentApi';
import { AppAction } from '..';
import {
  addDepartment,
  deleteDepartment,
  setDepartmentsError,
  setDepartmentsRequest,
  setDepartmentsSuccess,
  updateDepartment as updateDepartmentSlice,
} from './slice';

export const loadDepartments = (): AppAction<Promise<void>> => dispatch => {
  // dispatch(findDepartments())
  dispatch(setDepartmentsRequest());
  return departmentsApi.departments
    .getAllDepartments()
    .then(response => {
      dispatch(setDepartmentsSuccess(response));
    })
    .catch(error => {
      dispatch(setDepartmentsError(error));
    });
};

export const createDepartment =
  (name: string, alias?: string, leader?: string): AppAction<Promise<void>> =>
  dispatch => {
    return departmentsApi.departments
      .createDepartment(name, alias, leader)
      .then(response => {
        dispatch(addDepartment(response));
      });
  };

export const updateDepartment =
  (
    id: string,
    name?: string,
    alias?: string,
    leader?: string,
  ): AppAction<Promise<void>> =>
  dispatch => {
    // const currDept = selectDepartmentById(deptId)
    return departmentsApi.departments
      .updateDepartment(id, name, alias, leader)
      .then(resp => {
        // Temporary solution for the id. This
        dispatch(updateDepartmentSlice({ id: resp.id, changes: resp }));
      });
  };

export const removeDepartment =
  (id: string): AppAction<Promise<void>> =>
  dispatch => {
    return departmentsApi.departments.deleteDepartment(id).then(() => {
      dispatch(deleteDepartment(id));
    });
  };
