import { InputRowType } from 'app/types/InputRowType';
import Member from 'app/types/Member';

export const shouldEnableSaveGroups = (
  inputRowList: InputRowType[],
  members: Member[],
  groupCheckFunc: () => boolean,
) => {
  const doAllNamesExist = inputRowList
    .filter(item => !item.deleted)
    .every(item => item?.user?.displayName);
  if (!doAllNamesExist) {
    return false;
  }
  const val = groupCheckFunc();
  if (val) {
    return val;
  }

  return inputRowList.some(item => {
    const groupMember = members?.find(
      member => member.userId === item.user?.GUID,
    );
    const roleChanged = groupMember?.roleId !== item?.role?.id;

    if (item?.user?.displayName && !groupMember?.name) {
      return true;
    }

    if (roleChanged && item?.role !== null) {
      return true;
    }

    return item.deleted;
  });
};
