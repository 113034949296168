import { Container, Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import React, { ReactNode } from 'react';

const FAQHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        color: '#DD0031',
        fontWeight: 'bold',
        lineHeight: '2',
        fontFamily: 'Apercu',
      }}
    >
      {children}
    </div>
  );
};

const FAQContent = ({ children }: { children: ReactNode }) => {
  return <p style={{ textAlign: 'left', fontFamily: 'Apercu' }}>{children}</p>;
};

export const LeadershipFAQ = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        data-cy="faqs"
        style={{
          width: '40px',
          height: '40px',
          marginLeft: '10px',
          borderRadius: '50%',
          fontSize: '12px',
          background: '#F8C1B8',
          color: '#DD0031',
          border: 'none',
          fontFamily: 'Apercu',
        }}
        onClick={() => setOpen(true)}
      >
        FAQs
      </button>
      <Modal
        data-cy="close-faqs"
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
      >
        <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          <React.Fragment key=".0">
            <ModalHeader>
              <div style={{ color: '#DD0031', fontFamily: 'Apercu' }}>
                LEADERSHIP TEAMS FAQ
              </div>
            </ModalHeader>
            <ModalBody
              style={{ paddingBottom: '20px', textAlign: 'left' }}
              onClick={handleClose}
            >
              <FAQHeader>
                Why does it say “Insufficient Permissions” when I hover over a
                disabled button?
              </FAQHeader>
              <FAQContent>
                You do not have the correct WAS permissions to perform that
                action. You must have COUNCILS_ADMIN permissions to edit, add,
                and delete Leadership Teams.
              </FAQContent>
              <FAQHeader>How do I change my permission access?</FAQHeader>
              <FAQContent>
                Fill out a WAS Request in Service Gateway to add or remove
                permissions.
              </FAQContent>
              <FAQHeader>How do I create a Leadership Team?</FAQHeader>
              <FAQContent>
                Click on the Create Leadership Team button on the lower right
                side of the page. Add a leadership team name, description
                (optional), and the members of that team by pressing the Add
                Leadership Team Member button in the bottom left corner and
                filling out the name and roles of the leadership team members.
              </FAQContent>
              <FAQHeader>How do I view the Leadership Team Profile?</FAQHeader>
              <FAQContent>
                Click the title of the leadership team after finding the team in
                the Leadership Teams Page or the Search Page to open the team’s
                profile.
              </FAQContent>
              <FAQHeader>How do I delete a Leadership Team?</FAQHeader>
              <FAQContent>
                Open the leadership team’s profile and then click the Edit
                Leadership Team button. This will bring you to the edit page
                where you can delete the leadership team by clicking the Delete
                Leadership Team in the bottom right corner and typing in delete
                permanently. Only COUNCILS_ADMIN and ADMIN can perform this
                function.
              </FAQContent>
              <FAQHeader>
                How do I edit the members of a Leadership Team that already
                exists?
              </FAQHeader>
              <FAQContent>
                Open the leadership team’s profile and then click the Edit
                Leadership Team button in the top right corner. From there, you
                can edit the roles of existing members by clicking the box you
                are trying to change and selecting the new role. You can add new
                members by clicking “Add Leadership Team Members” and filling
                out the employee and role. You can also delete members by
                clicking the trash can. Click the Save button to save your
                changes or the Cancel button to revert changes.
              </FAQContent>
              <FAQHeader>
                How do I edit the description or title of the Leadership Team?
              </FAQHeader>
              <FAQContent>
                Open the leadership team’s profile and then click the Edit
                Leadership Team button in the top right corner. From there, you
                can open the editable textbox of the title and description by
                clicking the pencil button or the text itself. Click the pencil
                again to revert it back to normal text and click Save to save
                your changes or Cancel to revert changes.
              </FAQContent>
              <FAQHeader>
                The employee I am adding to a Leadership Team has a role that
                does not appear in the role selection field. How do I add a new
                role?
              </FAQHeader>
              <FAQContent>
                Click on the Admin button in the top navigation bar. Select
                View/Edit Leadership Team Roles. Click Create New, then enter
                the role name and click Create Role. Only Admins have access to
                this page.
              </FAQContent>
              <FAQHeader>
                How do I see the pictures of the members on my team?
              </FAQHeader>
              <FAQContent>
                Access the Leadership Team Profile Page by clicking on the title
                of the pod in the subdepartment’s page or on the Search Page.
                There, you can download a snapshot of your team’s photos to your
                device.
              </FAQContent>
              <Container style={{ textAlign: 'left' }}></Container>
            </ModalBody>
          </React.Fragment>
        </div>
      </Modal>
    </>
  );
};

export default LeadershipFAQ;
