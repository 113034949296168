import { OKTA_STORAGE } from 'app/constants/AppPermissions';
import { OktaToken } from './hasPermissions/hasPermission';

export const getOktaToken = (
  tokenName: string = OKTA_STORAGE,
): OktaToken | undefined => {
  const storedToken = localStorage.getItem(tokenName);

  if (storedToken) {
    return JSON.parse(storedToken);
  }

  return undefined;
};
