import IdentityUser from 'app/types/IdentityUser';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.IDENTITY);
const identityApi = {
  get_user_by_id: (cfaGUID: string, type: string) =>
    apiClient.get(`v2/identity/users/${type}/${cfaGUID}`).json<IdentityUser>(),
};

export default identityApi;
