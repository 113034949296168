import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { useAppSelector } from 'app/store';
import { selectGuildByID } from 'app/store/guilds/selectors';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import {
  Breadcrumbs,
  Button,
  LoadingIndicator,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { useNavigate, useParams } from 'react-router-dom';
import './Guilds.scss';

export const GuildProfilePage = () => {
  const navigate = useNavigate();
  const { guildId } = useParams() as { guildId: string };

  const guild = useAppSelector(selectGuildByID(guildId));
  const allowGuildEdit: boolean = allowEditGuild();

  const editGuilds = () => {
    navigate(`/guilds/${guild?.id}/edit`);
  };

  return guild ? (
    <>
      <Navigation />
      <div className="main-container">
        <div className="top-section">
          <Breadcrumbs
            breadcrumbs={[
              {
                label: 'Guilds',
                onClick: function backToGuilds() {
                  navigate('/guilds');
                },
              },
              {
                label: guild?.name,
              },
            ]}
          />
          {allowGuildEdit ? (
            <Button
              color="primary"
              onClick={editGuilds}
              size="md"
              variant="outlined"
              style={{
                justifySelf: 'end',
              }}
              disabled={!allowGuildEdit}
              data-cy="edit-guild-btn"
            >
              Edit Guild
            </Button>
          ) : (
            <Tooltip content="Insufficient Permissions">
              <span>
                <Button
                  color="primary"
                  onClick={editGuilds}
                  size="md"
                  variant="outlined"
                  style={{
                    justifySelf: 'end',
                  }}
                  disabled={!allowGuildEdit}
                  data-cy="edit-guild-btn"
                >
                  Edit Guild
                </Button>
              </span>
            </Tooltip>
          )}
        </div>
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            style={{
              marginLeft: '85px',
              marginRight: '85px',
            }}
          >
            {guild.name}
          </Typography>
        </div>
        <Typography
          variant="body1"
          color="secondary"
          fontWeight="medium"
          align="center"
          gutterBottom
          style={{
            minHeight: '50vh',
            marginTop: '30px',
            marginLeft: '85px',
            marginRight: '85px',
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          {guild.info}
        </Typography>
      </div>
      <Footer />
    </>
  ) : (
    <LoadingIndicator variant={'page'}></LoadingIndicator>
  );
};
