import { Button } from 'cfa-react-components';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.scss';
export const PageNotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/home');
  };

  return (
    <div>
      <img
        className="logo"
        src={require('../../../Icons/PageNotFound.png')}
        alt="Error"
      ></img>
      <div className="test-text" data-cy="404-page">
        We're sorry, but the page you're looking for can't be found.
      </div>
      <Button className="custom-button" onClick={handleClick}>
        Home
      </Button>
    </div>
  );
};

export default PageNotFound;
