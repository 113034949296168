import { useAppSelector } from 'app/store';
import { selectUsers } from 'app/store/users/selectors';
import IdentityUser from 'app/types/IdentityUser';
import Role from 'app/types/Role';
import { SearchDropdown, SearchDropdownAPI } from 'cfa-react-components';
import { useRef } from 'react';

type Props = {
  user: IdentityUser | null;
  setUser: (user: IdentityUser | null) => void;
  readOnly?: boolean;
  placeholder?: string;
  index?: number;
  handleInputChange?: (
    index: number,
    tempRole: Role | null,
    tempUser: IdentityUser | null,
    from: string,
  ) => void;
  originalLength?: number;
};
export const UserDropdown: React.FC<Props> = ({
  user,
  setUser,
  index,
  handleInputChange,
  placeholder,
  originalLength,
  ...args
}: Props) => {
  const users = useAppSelector(selectUsers);
  const searchDropdownRef = useRef<SearchDropdownAPI>(null);
  const getUsers = async (
    query: string,
    limit: number,
    offset: number,
  ): Promise<IdentityUser[]> => {
    // This returns a promise even though it has no await call as on Search requires a promise as a type
    if (!users) {
      return Promise.reject('Undefined');
    }
    return Promise.resolve<IdentityUser[]>(
      users
        .slice(offset, offset + limit)
        .filter(user =>
          user.displayName
            ?.toLocaleLowerCase()
            .includes(query.toLocaleLowerCase()),
        ),
    );
  };
  const placeholderText =
    !placeholder && user
      ? user?.displayName
        ? user.displayName
        : `${user?.firstName} ${user?.lastName}`
      : placeholder || 'Select a user';

  const disableDropdown = () => {
    if (originalLength !== undefined && index !== undefined) {
      return index < originalLength;
    }
    return false;
  };

  const disableButton = disableDropdown();

  return (
    <SearchDropdown<IdentityUser>
      onSearch={(query, _limit, offset) => {
        const limit = 100;
        if (!offset) {
          offset = 0;
        }
        return getUsers(query, limit, offset).then(data => {
          if (!users) {
            return { canLoadMore: true, results: [], cursor: offset };
          }
          return {
            canLoadMore: offset < users.length,
            results: data,
            cursor: offset + limit,
          };
        });
      }}
      label="Name"
      disabled={disableButton}
      ref={searchDropdownRef}
      onChange={val => {
        setUser(val ?? null);
        if (handleInputChange && index != undefined) {
          handleInputChange(index, null, val ?? null, 'USER');
        }
      }}
      value={user}
      placeholder={placeholderText}
      renderOption={user => {
        const userString = user.displayName
          ? user.displayName
          : `${user.firstName} ${user.lastName}`;

        return {
          id: user.GUID,
          textValue: userString,
          rendered: userString,
        };
      }}
      required
      fullWidth
      data-cy={`user-dropdown-${index}`}
      {...args}
    />
  );
};

export default UserDropdown;
