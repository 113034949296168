import { Button } from 'cfa-react-components';
import { NavLink } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import './Home.scss';

// Home page cool stuff!!

export const Home = () => {
  return (
    <>
      <Navigation />
      <div className="home-hero-container" data-cy="home-container">
        <h1 className="home-welcome-title" data-cy="home-title">
          Welcome to Digital Transformation <br></br> & Technology TeamView!
        </h1>
      </div>
      <div className="explorePods">
        <h2 className="clickTheButton" data-cy="button-explanation">
          Click the buttons below to explore DTT TeamView!
        </h2>
        <div className="buttonsEdit">
          <Button
            className="podsButton"
            as={NavLink}
            to="/pods"
            data-cy="pods-button"
          >
            Explore Pods
          </Button>
          <Button
            className="ltButton"
            as={NavLink}
            to="/leadership-teams"
            data-cy="lt-button"
          >
            Explore Leadership Teams
          </Button>
          <Button
            className="guildsButton"
            as={NavLink}
            to="/guilds"
            data-cy="guilds-button"
          >
            Explore Guilds
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
