import Footer from 'app/components/Footer/Footer';
import Navigation from 'app/components/Navigation/Navigation';
import { CreateGuildButton } from 'app/components/shared/FormDialog/CreateGuild';
import GuildFAQ from 'app/components/shared/FormDialog/GuildFAQ';
import GuildCard from 'app/components/shared/GuildsCard/GuildsCard';
import { useAppSelector } from 'app/store';
import { selectGuilds } from 'app/store/guilds/selectors';
import { Guild } from 'app/types/Guild';
import { allowEditGuild } from 'app/utils/hasPermissions/allowFeature';
import { List, ListItem, Typography } from 'cfa-react-components';
import './Guilds.scss';

export const Guilds = () => {
  const guildsList: Guild[] = useAppSelector(selectGuilds);
  const allowGuildCreate: boolean = allowEditGuild();
  return (
    <>
      <Navigation />
      <div className="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
          >
            Guilds
          </Typography>
        </div>
        <div
          style={{
            marginRight: '10px',
          }}
        >
          <List
            className="guilds-List"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(450px, 1fr))', // Adjust the column width as needed
              gridGap: '20px',
            }}
          >
            {guildsList.map(guild => {
              return (
                <ListItem
                  key={guild.id}
                  style={{
                    width: '450px',
                    maxWidth: '450px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    alignContent: 'center',
                  }}
                >
                  <GuildCard guild={guild} />
                </ListItem>
              );
            })}
          </List>
        </div>
      </div>
      <Footer />
      <div className="create-guild-button">
        <CreateGuildButton allowGuildCreate={allowGuildCreate} />
        <GuildFAQ />
      </div>
    </>
  );
};

export default Guilds;
