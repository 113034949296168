import RoleType from 'app/constants/ApiRole';
import { RootState, useAppSelector } from '..';
import { roleAdapter } from './slice';

export const selectRoleReducerState = (store: RootState) => store.role;

const roleSelectors = roleAdapter.getSelectors(selectRoleReducerState);

export const selectRoles = (store: RootState) => roleSelectors.selectAll(store);

export const usePodRoles = () => {
  const appSelector = useAppSelector(selectRoles);
  return appSelector.filter(a => a.type === RoleType.POD_ROLE);
};
export const useConsultantRoles = () => {
  const appSelector = useAppSelector(selectRoles);
  return appSelector.filter(a => a.type === RoleType.CONSULTANT_ROLE);
};
export const useLeadershipRoles = () => {
  const appSelector = useAppSelector(selectRoles);
  return appSelector.filter(a => a.type === RoleType.LT_ROLE);
};

export const selectRoleById = (roleId: string) => (store: RootState) =>
  roleSelectors.selectById(store, roleId);
