import { Dispatch, RefObject, SetStateAction, useEffect } from 'react';

export const useRevertEdit = (
  ref: RefObject<HTMLElement>,
  setEditMode: Dispatch<SetStateAction<boolean>>,
  targetClass: string,
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        ref.current &&
        !ref.current.contains(event.target as Node) &&
        !target?.classList.contains(targetClass)
      ) {
        setEditMode(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setEditMode, targetClass]);
};
