// Create APi Client
import { getOktaAccessToken } from 'app/utils/getOktaAccessToken';
import ky from 'ky';

const createApiClient = (baseUrl: string) => {
  return ky.create({
    prefixUrl: baseUrl,
    hooks: {
      beforeRequest: [
        (request, options) => {
          if (request.headers.get('Authorization') === null) {
            request.headers.set(
              'Authorization',
              'Bearer ' + getOktaAccessToken(),
            );
          }
        },
      ],
      beforeError: [
        async error => {
          if (error.response) {
            const m = await error.response.json();
            error.message = JSON.stringify(m);
          }
          return error;
        },
      ],
    },
  });
};

export default createApiClient;
