import RoleType from 'app/constants/ApiRole';
import { GroupWithMembers } from 'app/types/Group';
import IdentityUser from 'app/types/IdentityUser';
import { InputRowType } from 'app/types/InputRowType';
import Member from 'app/types/Member';
import Role from 'app/types/Role';

export const getMembersChangeSet = <T extends GroupWithMembers>(
  group: T,
  groupName: string,
  groupDescription: string,
  rolesList: Role[],
  members: Member[],
  currentRowList: InputRowType[],
  roleType: RoleType,
  users: IdentityUser[],
) => {
  let tempGroup: T = { ...group, name: groupName!, info: groupDescription };
  const { added, deleted, updated } = compareLists(
    members ?? [],
    currentRowList,
  );
  if (added.length === 0 && deleted.length === 0 && updated.length === 0) {
    return undefined;
  }

  let newMembers: IdentityUser[] = [];
  const addedRoles: Role[] = [];
  added.forEach(async member => {
    let user = users.find(user => user.GUID === member.user_id);
    let role = rolesList.find(role => role.id === member.role_id);
    if (user && group) {
      newMembers.push(user);
      if (role) {
        addedRoles.push(role);
      } else {
        const emptyRole: Role = {
          id: '',
          name: '',
          type: roleType,
          createdAt: new Date(),
          updatedAt: undefined,
          deletedAt: undefined,
        };
        addedRoles.push(emptyRole);
      }
    }
  });

  let deletedMemberIds: string[] = [];
  deleted.forEach(async memberId => {
    deletedMemberIds.push(memberId);
  });

  let changedMembers: Member[] = [];
  const updatedRoles: Role[] = [];

  updated.forEach(async member => {
    const role = rolesList.find(role => role.id === member.role_id);
    const groupMember = members?.find(
      cMember => cMember.userId === member.user_id,
    );
    if (role && groupMember && group) {
      changedMembers.push(groupMember);
      updatedRoles.push(role);
    }
  });

  return {
    tempGroup,
    deletedMemberIds,
    newMembers,
    addedRoles,
    changedMembers,
    updatedRoles,
  };
};

const compareLists = (podMembers: Member[], inputRowList: InputRowType[]) => {
  const originalPodMembers = new Map<string, string>();
  const currentMembers = new Map<string, string>();
  podMembers.forEach(member => {
    originalPodMembers.set(member.userId, member.roleId ?? '');
  });
  inputRowList.forEach(member => {
    if (member.deleted) {
      return;
    }
    if (member.user?.GUID) {
      currentMembers.set(member.user?.GUID, member.role?.id ?? '');
    }
  });

  const deletedList = [] as string[];
  for (const [user_id, role_id] of originalPodMembers.entries()) {
    if (!currentMembers.has(user_id)) {
      deletedList.push(user_id);
    }
  }
  const addedList = [];
  const updatedList = [];
  for (const [user_id, role_id] of currentMembers.entries()) {
    if (!originalPodMembers.has(user_id)) {
      addedList.push({ user_id: user_id, role_id: role_id });
    } else if (originalPodMembers.get(user_id) !== role_id) {
      updatedList.push({ user_id: user_id, role_id: role_id });
    }
  }
  return { added: addedList, deleted: deletedList, updated: updatedList };
};
