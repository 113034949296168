import memberApi from 'app/services/MembersApi';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import Role from 'app/types/Role';
import { AppAction } from '..';
import {
  addConsultant,
  deleteConsultant,
  setConsultantsError,
  setConsultantsRequest,
  setConsultantsSuccess,
  updateConsultant as updateConsultantSlice,
} from './slice';

export const loadConsultants = (): AppAction<Promise<void>> => dispatch => {
  dispatch(setConsultantsRequest());
  return memberApi.members
    .getMembersByType(MemberType.CONSULTANT)
    .then(resp => {
      dispatch(setConsultantsSuccess(resp));
    })
    .catch(error => {
      dispatch(setConsultantsError(error));
    });
};

export const createConsultant =
  (
    user: IdentityUser,
    dept: Department,
    role: Role,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .createMember(
        {
          userId: user.GUID,
          groupId: dept.id,
          type: MemberType.CONSULTANT,
          name: user.displayName as string,
          isContingent: user.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
          status: MemberStatus.ACTIVE,
        },
        role?.id,
      )
      .then(member => {
        dispatch(addConsultant(member));
      });
  };

export const updateConsultant =
  (
    member: Member,
    dept: Department,
    newRole?: Role,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .updateMember(
        member.userId,
        dept.id,
        member.type,
        newRole?.id,
        member.startDate,
        member.endDate,
        member.status,
      )
      .then(member => {
        dispatch(
          updateConsultantSlice({
            id: member.userId + member.groupId,
            changes: member,
          }),
        );
      });
  };

export const removeConsultant =
  (member: Member, dept: Department): AppAction<Promise<void>> =>
  dispatch => {
    return memberApi.members
      .deleteMember(member.groupId, member.userId)
      .then(resp => {
        dispatch(deleteConsultant(member.userId + member.groupId));
      });
  };
