import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Role } from 'app/types/Role';

interface RoleState {
  entities: {
    [key: string]: Role;
  };
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

// export const initialState: RoleState = {
//   entities: {},
//   status:'idle',
// };

export const roleAdapter = createEntityAdapter<Role>({
  selectId: role => role.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = roleAdapter.getInitialState({
  status: 'idle',
});

const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setAllRoles: roleAdapter.setAll,
    addRole: roleAdapter.addOne,
    deleteRole: roleAdapter.removeOne,
    updateRole: roleAdapter.updateOne,
  },
});

export const { setAllRoles, addRole, deleteRole, updateRole } =
  roleSlice.actions;

export default roleSlice;
