import CreateLeaderRole from 'app/components/shared/FormDialog/CreateLTRole';
import { useAppSelector } from 'app/store';
import { selectDepartments } from 'app/store/dept/selectors';
import {
  useConsultantRoles,
  useLeadershipRoles,
  usePodRoles,
} from 'app/store/roles/selectors';
import {
  Card,
  CardContent,
  Divider,
  TabItem,
  TabPanel,
  Tabs,
  Typography,
} from 'cfa-react-components';
import React, { useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Navigation from '../../components/Navigation/Navigation';
import { CreateConsultantRole } from '../../components/shared/FormDialog/CreateConsultantRole';
import { CreateDept } from '../../components/shared/FormDialog/CreateDept';
import { CreateRole } from '../../components/shared/FormDialog/CreateRole';
import { EditDept } from '../../components/shared/FormDialog/EditDept';
import { EditRole } from '../../components/shared/FormDialog/EditRole';
import './Admin.scss';

export const Admin = () => {
  const [currPage, setCurrPage] = useState('Role');
  const deptList = useAppSelector(selectDepartments);
  const podRoles = usePodRoles();
  const consultantRoles = useConsultantRoles();
  const leadershipRoles = useLeadershipRoles();

  return (
    <div>
      <Navigation />
      <div className="main_container" data-cy="main-container">
        <div className="title-section">
          <Typography
            variant="h2"
            color="primary"
            fontWeight="bold"
            align="center"
            gutterBottom
            style={{ marginTop: '30px' }}
          >
            Admin
          </Typography>
        </div>
        <React.Fragment key=".0">
          <div className="nav_section">
            <Tabs activeItemKey={currPage} onChange={e => setCurrPage(e)}>
              <TabItem data-cy="roles-tab" itemKey="Role">
                View/Edit Pod Roles
              </TabItem>
              <TabItem data-cy="dept-tab" itemKey="Subdept">
                View/Edit Sub-Departments
              </TabItem>
              <TabItem data-cy="consultant-tab" itemKey="Consultant">
                View/Edit Consultant Roles
              </TabItem>
              <TabItem data-cy="leadership-tab" itemKey="LeadershipTeams">
                View/Edit Leadership Teams Roles
              </TabItem>
            </Tabs>
          </div>
          <TabPanel itemKey="Role" activeItemKey={currPage}>
            <React.Fragment key=".0">
              <Card className="main_card" elevation={8} style={{ width: 488 }}>
                <CardContent style={{ maxWidth: 488 }}>
                  <Typography
                    data-cy="role"
                    className="role_header"
                    variant="h2"
                    align="center"
                  >
                    POD ROLES
                  </Typography>
                  <Divider
                    key={'divider'}
                    style={{ width: 488, marginInlineStart: -16 }}
                  />
                </CardContent>
                <CardContent
                  className="roles_list"
                  style={{ height: 445, maxHeight: 445, width: 448 }}
                >
                  {/* This can be it's own component */}
                  {podRoles &&
                    podRoles.map(role => (
                      <>
                        <div
                          className="role_list_items"
                          style={{ padding: 10 }}
                        >
                          <Typography variant="body1" color="secondary">
                            {role.name}
                          </Typography>
                          <EditRole role={role} />
                        </div>
                        <Divider variant="fullLength" />
                      </>
                    ))}
                </CardContent>
                <CreateRole />
              </Card>
            </React.Fragment>
          </TabPanel>
          <TabPanel itemKey="Subdept" activeItemKey={currPage}>
            <React.Fragment key=".0">
              <Card className="main_card" elevation={8} style={{ width: 488 }}>
                <CardContent style={{ maxWidth: 488 }}>
                  <Typography
                    data-cy="dept"
                    className="role_header"
                    variant="h2"
                    align="center"
                  >
                    SUB-DEPARTMENTS
                  </Typography>
                  <Divider style={{ width: 488, marginInlineStart: -16 }} />
                </CardContent>
                <CardContent
                  className="roles_list"
                  style={{ height: 445, maxHeight: 445, width: 448 }}
                >
                  {deptList &&
                    deptList.map(subdept => (
                      <>
                        <Typography
                          className="subdept_list_items"
                          variant="body1"
                          color="secondary"
                          style={{ padding: 10 }}
                        >
                          {subdept.name}
                          <EditDept
                            dept={subdept}
                            subDeptName={subdept.name}
                            subdeptID={subdept.id}
                          />
                        </Typography>
                        <Divider variant="fullLength" />
                      </>
                    ))}
                </CardContent>
                <CreateDept />
              </Card>
            </React.Fragment>
          </TabPanel>
          <TabPanel itemKey="Consultant" activeItemKey={currPage}>
            <React.Fragment key=".0">
              <Card className="main_card" elevation={8} style={{ width: 488 }}>
                <CardContent style={{ maxWidth: 488 }}>
                  <Typography
                    data-cy="consultant"
                    className="role_header"
                    variant="h2"
                    align="center"
                  >
                    CONSULTANT ROLES
                  </Typography>
                  <Divider style={{ width: 488, marginInlineStart: -16 }} />
                </CardContent>
                <CardContent
                  className="roles_list"
                  style={{ height: 445, maxHeight: 445, width: 448 }}
                >
                  {consultantRoles &&
                    consultantRoles.map(role => (
                      <div>
                        <Typography
                          className="role_list_items"
                          variant="body1"
                          color="secondary"
                          style={{ padding: 10 }}
                        >
                          {role.name}
                          <EditRole role={role} />
                        </Typography>
                        <Divider variant="fullLength" />
                      </div>
                    ))}
                </CardContent>
                <CreateConsultantRole />
              </Card>
            </React.Fragment>
          </TabPanel>
          <TabPanel itemKey="LeadershipTeams" activeItemKey={currPage}>
            <React.Fragment key=".0">
              <Card className="main_card" elevation={8} style={{ width: 488 }}>
                <CardContent style={{ maxWidth: 488 }}>
                  <Typography
                    data-cy="leadership"
                    className="role_header"
                    variant="h2"
                    align="center"
                  >
                    LEADERSHIP TEAMS
                  </Typography>
                  <Divider style={{ width: 488, marginInlineStart: -16 }} />
                </CardContent>
                <CardContent
                  className="roles_list"
                  style={{ height: 445, maxHeight: 445, width: 448 }}
                >
                  {leadershipRoles &&
                    leadershipRoles.map(role => (
                      <div>
                        <Typography
                          className="role_list_items"
                          variant="body1"
                          color="secondary"
                          style={{ padding: 10 }}
                        >
                          {role.name}
                          <EditRole role={role} />
                        </Typography>
                        <Divider variant="fullLength" />
                      </div>
                    ))}
                </CardContent>
                <CreateLeaderRole />
              </Card>
            </React.Fragment>
          </TabPanel>
        </React.Fragment>
        <Footer />
      </div>
    </div>
  );
};

export default Admin;
