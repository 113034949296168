import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectAppReducerState = (store: RootState) => store.app;

export const selectIsInitialized = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.initialized,
);

export const selectSelectedDeptTab = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.selectedDeptTab,
);
export const selectSelectedAdminTab = createSelector(
  selectAppReducerState,
  appReducerState => appReducerState.selectedAdminTab,
);

export const selectOktaUser = createSelector(
  selectAppReducerState,
  appReucerState => appReucerState.currentUser.okta,
);
export const selectIdentityUser = createSelector(
  selectAppReducerState,
  appReucerState => appReucerState.currentUser.identity,
);
