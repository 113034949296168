import { Container, Modal, ModalBody, ModalHeader } from 'cfa-react-components';
import React, { ReactNode } from 'react';

const FAQHeader = ({ children }: { children: ReactNode }) => {
  return (
    <div
      style={{
        color: '#DD0031',
        fontWeight: 'bold',
        lineHeight: '2',
        fontFamily: 'Apercu',
      }}
    >
      {children}
    </div>
  );
};

const FAQContent = ({ children }: { children: ReactNode }) => {
  return <p style={{ textAlign: 'left', fontFamily: 'Apercu' }}>{children}</p>;
};

export const PodFAQ = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        data-cy="faqs"
        style={{
          width: '40px',
          height: '40px',
          marginLeft: '10px',
          borderRadius: '50%',
          fontSize: '12px',
          background: '#F8C1B8',
          color: '#DD0031',
          border: 'none',
          fontFamily: 'Apercu',
        }}
        onClick={() => setOpen(true)}
      >
        FAQs
      </button>
      <Modal
        data-cy="close-faqs"
        show={open}
        onClose={handleClose}
        size="lg"
        scrollMode="modal-body"
      >
        <div style={{ maxHeight: '100vh', overflowY: 'auto' }}>
          <React.Fragment key=".0">
            <ModalHeader>
              <div style={{ color: '#DD0031', fontFamily: 'Apercu' }}>
                PODS FAQ
              </div>
            </ModalHeader>
            <ModalBody
              style={{ paddingBottom: '20px', textAlign: 'left' }}
              onClick={handleClose}
            >
              <FAQHeader>
                Why does it say “Insufficient Permissions” when I hover over a
                disabled button?
              </FAQHeader>
              <FAQContent>
                You do not have the correct WAS permissions to perform that
                action. You must have PODS_ADMIN permissions to edit, add, and
                delete Pods.
              </FAQContent>
              <FAQHeader>How do I change my permission access?</FAQHeader>
              <FAQContent>
                Fill out a WAS Request in Service Gateway to add or remove
                permissions.
              </FAQContent>
              <FAQHeader>How do I create a Pod?</FAQHeader>
              <FAQContent>
                Click on the Create Pod button on the lower right side of the
                page. Add a pod name, description (optional), and the members of
                that pod by pressing the Add Pod Member button in the bottom
                left corner and filling out the name and roles of the pod
                members.
              </FAQContent>
              <FAQHeader>How do I view the Pod Profile?</FAQHeader>
              <FAQContent>
                Click the title of the pod after finding the pod in the
                Subdepartment Page or the Search Page to open the pod’s profile.
              </FAQContent>
              <FAQHeader>How do I delete a Pod?</FAQHeader>
              <FAQContent>
                Open the pod’s profile and then click the Edit Pod button. This
                will bring you to the edit page where you can delete the pod by
                clicking the Delete Pod in the bottom right corner and typing in
                delete permanently. Only PODS_ADMIN and ADMIN can perform this
                function.
              </FAQContent>
              <FAQHeader>
                How do I edit the members of a Pod that already exists?
              </FAQHeader>
              <FAQContent>
                Open the pod’s profile and then click the Edit Pod button in the
                top right corner. From there, you can edit the roles of existing
                members by clicking the box you are trying to change and
                selecting the new role. You can add new members by clicking “Add
                Pod Members” and filling out the employee and role. You can also
                delete members by clicking the trash can. Click the Save button
                to save your changes or the Cancel button to revert changes.
              </FAQContent>
              <FAQHeader>
                How do I edit the description or title of the Pod?
              </FAQHeader>
              <FAQContent>
                Open the pod’s profile and then click the Edit Pod button in the
                top right corner. From there, you can open the editable textbox
                of the title and description by clicking the pencil button or
                the text itself. Click the pencil again to revert it back to
                normal text and click Save to save your changes or Cancel to
                revert changes.
              </FAQContent>
              <FAQHeader>What are subdepartment consultants?</FAQHeader>
              <FAQContent>
                Subdepartment consultants are DTT or non-DTT staff who partner
                with that subdepartment in their area of expertise.
              </FAQContent>
              <FAQHeader>Can I add consultants for my subdepartment?</FAQHeader>
              <FAQContent>
                Yes. Click on the Pods tab, then select your subdepartment.
                Click on Subdepartment Consultants, then the + button to add a
                consultant. Select an employee and assign a role, then click Add
                Consultant.
              </FAQContent>
              <FAQHeader>
                The employee I am adding to a Pod has a role that does not
                appear in the role selection field. How do I add a new role?
              </FAQHeader>
              <FAQContent>
                Contact an ADMIN and request that the new role is added to DTT
                TeamView.
              </FAQContent>
              <FAQHeader>
                How do I see the pictures of the members on my team?
              </FAQHeader>
              <FAQContent>
                Access the Pod Profile Page by clicking on the title of the pod
                in the subdepartment’s page or on the Search Page. There, you
                can download a snapshot of your team’s photos to your device.
              </FAQContent>
              <FAQHeader>
                Why do some employees have blue names, and some employees have
                yellow names?
              </FAQHeader>
              <FAQContent>
                Full-time staff members have blue names, and contingent
                workers/contractors have yellow names.
              </FAQContent>
              <Container style={{ textAlign: 'left' }}></Container>
            </ModalBody>
          </React.Fragment>
        </div>
      </Modal>
    </>
  );
};

export default PodFAQ;
