import identityApi from 'app/services/IdentityApi';
import Department from 'app/types/Department';
import IdentityUser from 'app/types/IdentityUser';
import Member from 'app/types/Member';
import Role from 'app/types/Role';
import { Container, Divider, ListItem, Typography } from 'cfa-react-components';
import { useEffect, useState } from 'react';
import EmployeePicture from '../EmployeePicture';
import EditConsultant from '../FormDialog/EditConsultant';

export type ConsultantAccordionListItemProps = {
  consultant: Member;
  roleList: Role[];
  currentDept: Department;
};

export const ConsultantAccordionListItem = ({
  consultant,
  roleList,
  currentDept,
}: ConsultantAccordionListItemProps) => {
  const [consultantRoleName, setConsultantRoleName] = useState<string>('');
  const [user, setUser] = useState<IdentityUser>();

  useEffect(() => {
    identityApi
      .get_user_by_id(
        consultant.userId,
        consultant.isContingent ? 'contractors' : 'staff',
      )
      .then(data => setUser(data))
      .catch(e => console.log('Error occurred while fetching user:', e));

    let role =
      roleList.find(role => role.id === consultant.roleId) ?? undefined;
    role
      ? setConsultantRoleName(role.name)
      : setConsultantRoleName('Consultant');
  }, [roleList]);

  return (
    <>
      <ListItem
        startItem={
          <Container style={{ marginTop: '7px' }}>
            <EmployeePicture user={user!} imgHeight="30px" imgWidth="30px" />
          </Container>
        }
        endItem={
          <EditConsultant
            currentDepartment={currentDept}
            rolesList={roleList}
            consultant={consultant}
          />
        }
        style={{ width: '100%' }}
      >
        <Typography
          variant="body1"
          color="secondary"
          fontWeight="bold"
          align="left"
          style={{ paddingTop: '10px', paddingLeft: '10px' }}
        >
          {consultantRoleName}: {consultant.name}
        </Typography>
        <Divider variant="fullLength" style={{ paddingTop: '10px' }} />
      </ListItem>
    </>
  );
};

export default ConsultantAccordionListItem;
