import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import Member from 'app/types/Member';

interface ConsultantsState {
  // Key is department id
  entities: Member[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

export const ConsultantsAdapter = createEntityAdapter<Member>({
  selectId: a => a.userId + a.groupId,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = ConsultantsAdapter.getInitialState({
  apiStatus: {
    loadConsultants: defaultApiStatus,
    updateConsultant: defaultApiStatus,
    addConsultant: defaultApiStatus,
    removeConsultant: defaultApiStatus,
  },
});

const consultantsSlice = createSlice({
  name: 'consultants',
  initialState,
  reducers: {
    addConsultant: ConsultantsAdapter.addOne,
    updateConsultant: ConsultantsAdapter.updateOne,
    deleteConsultant: ConsultantsAdapter.removeOne,
    setConsultantsRequest: state => {
      state.apiStatus.loadConsultants.state = ApiState.LOADING;
      state.apiStatus.loadConsultants.error = null;
    },
    setConsultantsSuccess: (state, action: PayloadAction<Member[]>) => {
      state.apiStatus.loadConsultants.state = ApiState.IDLE;
      state.apiStatus.loadConsultants.error = null;
      ConsultantsAdapter.setAll(state, action);
    },
    setConsultantsError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadConsultants.state = ApiState.ERROR;
      state.apiStatus.loadConsultants.error = action.payload;
    },
  },
});

export const {
  addConsultant,
  updateConsultant,
  deleteConsultant,
  setConsultantsRequest,
  setConsultantsSuccess,
  setConsultantsError,
} = consultantsSlice.actions;

export default consultantsSlice;
