import { ACTIONS, Severity, UserMessaging } from 'app/constants/UserMessaging';
import { MessageBlock, MessageBlockContent } from 'cfa-react-components';
import toast, { useToasterStore } from 'react-hot-toast';
import AppConstants from '../AppConstants';

const displayToast = (
  message: string,
  severity: Severity,
  type: string,
  title: string,
  uuid?: string,
) => {
  const id = `toast ${type} ${uuid}`;
  toast.custom(
    <MessageBlock
      severity={severity}
      title={title}
      onClose={() => toast.dismiss(id)}
      data-cy={id}
    >
      <MessageBlockContent>{message}</MessageBlockContent>
    </MessageBlock>,
    {
      id,
      position: 'top-center',
      duration: severity === 'error' ? Infinity : AppConstants.TOAST_DURATION,
    },
  );
};

export const useActiveToast = () => {
  const toastStore = useToasterStore();
  return toastStore.toasts.length;
};

// TODO: fix error codes in backend to be more descriptive

// TODO: put display toast in more actions if trying to do things async
// Check guilds page for the custom function to simplify action, succss, failure. Evaluate if this is too rigid or gives us fleixibility

// TODO: write tests for display toasts errors with reusable test functions
export const displayErrorToast = (
  message: string,
  action: ACTIONS,
  id?: string,
) => {
  return displayToast(
    buildErrorMessage(action, message),
    Severity.ERROR,
    action,
    getTitle(action, Severity.ERROR) ?? 'Error',
    id,
  );
};

//---------------------------------------------------------------------------------//

const buildErrorMessage = (action: ACTIONS, message: string) => {
  return (
    getActionMessages(action).error?.description + ' Error Message: ' + message
  );
};

const getTitle = (action: ACTIONS, type: Severity) => {
  return getActionMessages(action)[type]?.title;
};

const getActionMessages = (action: ACTIONS) => {
  return UserMessaging.toast[action] ?? UserMessaging.toast.default;
};
