import { ACTIONS } from 'app/constants/UserMessaging';
import leadershipApi from 'app/services/LeadershipApi';
import memberApi from 'app/services/MembersApi';
import IdentityUser from 'app/types/IdentityUser';
import LeadershipTeam, { Leadership } from 'app/types/Leadership';
import Member from 'app/types/Member';
import MemberStatus from 'app/types/MemberStatus';
import MemberType from 'app/types/MemberType';
import Role from 'app/types/Role';
import { displayErrorToast } from 'app/utils/toasts/displayToast';
import { AppAction } from '..';
import {
  addLeadership,
  deleteLeadership,
  reloadLeadership,
  setLeadershipError,
  setLeadershipRequest,
  setLeadershipSuccess,
  updateLeadership as updateLeadershipSlice,
} from './slice';

export const loadTeams = (): AppAction<Promise<void>> => dispatch => {
  dispatch(setLeadershipRequest());
  return leadershipApi.leadership
    .getAllLeadership()
    .then(async resp => {
      const leadershipTeams: LeadershipTeam[] = [];
      await Promise.all(
        resp.map(leadership =>
          memberApi.members
            .getMembersByGroup(MemberType.LEADERSHIP, leadership.id)
            .then(members => {
              leadership = { ...leadership, members: members };
              leadershipTeams.push(leadership);
            })
            .catch(error => {
              console.log(error);
              leadership = { ...leadership, members: [] };
              leadershipTeams.push(leadership);
            }),
        ),
      );
      dispatch(setLeadershipSuccess(leadershipTeams));
    })
    .catch(error => {
      dispatch(setLeadershipError(error));
    });
};

export const reloadTeam =
  (id: string): AppAction<Promise<void>> =>
  dispatch => {
    return leadershipApi.leadership
      .getLeadership(id)
      .then(async resp => {
        await memberApi.members
          .getMembersByGroup(MemberType.LEADERSHIP, resp.id)
          .then(members => {
            resp = { ...resp, members: members };
            dispatch(reloadLeadership(resp));
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        dispatch(setLeadershipError(error));
      });
  };

export const createLeadership =
  (
    name: string,
    info?: string,
    members?: Member[],
    deptId?: string,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return leadershipApi.leadership
      .createLeadership(name, info, deptId)
      .then(async response => {
        let tempLeadershipMembers: Member[] = [];
        if (members) {
          members.forEach(member => (member.groupId = response.id));
          await Promise.all(
            members.map(async member => {
              return await memberApi.members.createMember(
                {
                  userId: member.userId,
                  groupId: member.groupId,
                  type: member.type,
                  name: member.name,
                  isContingent: member.isContingent as boolean,
                  status: member.status,
                },
                member.roleId,
              );
            }),
          )
            .then(resp => {
              resp.forEach(member => tempLeadershipMembers.push(member));
            })
            .catch(error => {
              throw error;
            });
          const newLeadership: Leadership = {
            ...response,
            members: tempLeadershipMembers,
          };
          dispatch(addLeadership(newLeadership));
        }
      });
  };

export const createLeadershipMembers =
  (
    leadership: LeadershipTeam,
    users: IdentityUser[],
    roles?: Role[],
  ): AppAction<Promise<void>> =>
  dispatch => {
    return Promise.all(
      users.map(async (user, index) => {
        const roleId = roles && roles[index] && roles[index].id;
        return await memberApi.members
          .createMember(
            {
              userId: user.GUID,
              groupId: leadership.id,
              type: MemberType.LEADERSHIP,
              name: user.displayName ?? '',
              status: MemberStatus.ACTIVE,
              isContingent: user.AUDIENCE_TAG === 'CONTRACTOR_AUDIENCE',
            },
            roleId,
          )
          .catch(error => {
            displayErrorToast(error.message, ACTIONS.createMember, user.GUID);
          });
      }),
    )
      .then(members => {
        let tempMembers: Member[] = leadership.members
          ? [...leadership.members]
          : [];

        members.forEach(member => {
          if (member === undefined) {
            return;
          }
          tempMembers.push(member);
        });
        const tempLeadership: LeadershipTeam = {
          ...leadership,
          members: tempMembers,
        };
        dispatch(
          updateLeadershipSlice({ id: leadership.id, changes: tempLeadership }),
        );
      })
      .catch(error => {
        console.log(error);
      });
  };

export const updateLeadershipTeam =
  (
    leadershipEntityId: string,
    newName?: string,
    newInfo?: string,
    newDept?: string,
  ): AppAction<Promise<void>> =>
  dispatch => {
    return leadershipApi.leadership
      .updateLeadership(leadershipEntityId, newName, newInfo, newDept)
      .then(resp => {
        dispatch(
          updateLeadershipSlice({ id: leadershipEntityId, changes: resp }),
        );
      });
  };

export const updateLeadershipMembers =
  (
    leadershipTeam: LeadershipTeam,
    members: Member[],
    newRoles?: Role[],
  ): AppAction<Promise<void>> =>
  dispatch => {
    return Promise.all(
      members.map(async (member, index) => {
        const role = newRoles && newRoles[index];
        return await memberApi.members
          .updateMember(
            member.userId,
            leadershipTeam.id,
            member.type,
            role?.id,
            member.startDate,
            member.endDate,
            member.status,
          )
          .catch(error => {
            displayErrorToast(error.message, ACTIONS.editMember, member.userId);
          });
      }),
    )
      .then(members => {
        const tempMembers: Member[] = leadershipTeam.members
          ? [...leadershipTeam.members]
          : [];
        members.forEach(member => {
          if (member === undefined) {
            return;
          }
          const index = tempMembers.findIndex(
            a => a.userId.localeCompare(member.userId) === 0,
          );
          tempMembers[index] = member;
        });
        const tempTeam: LeadershipTeam = {
          ...leadershipTeam,
          members: tempMembers,
        };
        dispatch(
          updateLeadershipSlice({ id: leadershipTeam.id, changes: tempTeam }),
        );
      })
      .catch(error => {
        throw error;
      });
  };

export const removeLeadershipTeam =
  (leadershipEntityId: string): AppAction<Promise<void>> =>
  dispatch => {
    return leadershipApi.leadership
      .deleteLeadership(leadershipEntityId)
      .then(resp => {
        dispatch(deleteLeadership(leadershipEntityId));
      });
  };

export const removeLeadershipMembers =
  (
    leadershipTeam: LeadershipTeam,
    memberIds: string[],
  ): AppAction<Promise<void>> =>
  dispatch => {
    let tempMembers: Member[] = [...leadershipTeam.members!];
    return Promise.all(
      memberIds.map(async (memberId, index) => {
        return await memberApi.members
          .deleteMember(leadershipTeam.id, memberId)
          .then(resp => {
            const index = tempMembers.findIndex(a => a.userId === memberId);
            tempMembers.splice(index, 1);
          })
          .catch(error => {
            displayErrorToast(error.message, ACTIONS.deleteMember, memberId);
          });
      }),
    )
      .then(members => {
        const tempTeam: LeadershipTeam = {
          ...leadershipTeam,
          members: tempMembers,
        };
        dispatch(
          updateLeadershipSlice({ id: leadershipTeam.id, changes: tempTeam }),
        );
      })
      .catch(error => {
        throw error;
      });
  };
