import Member from 'app/types/Member';
import { Typography } from 'cfa-react-components';
import { ProfileTag } from '../ProfileTag/ProfileTag';

export type MemberRoleSectionProps = {
  roleName: string;
  members: Member[];
};

export const MemberRoles = ({ roleName, members }: MemberRoleSectionProps) => {
  return (
    <>
      <Typography variant="body1" fontWeight="bold">
        {roleName}
      </Typography>
      {members.map(member => {
        return <ProfileTag key={member.userId} member={member} />;
      })}
    </>
  );
};

export default MemberRoles;
