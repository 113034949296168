import { Pencil } from '@cfa-icons/system';
import { useRevertEdit } from 'app/utils/hooks/useRevertEdit';
import {
  Icon,
  TextField,
  TextFieldType,
  Typography,
} from 'cfa-react-components';
import { useRef, useState } from 'react';
import styled from 'styled-components';

// Shared Component for editing the title of a page on a group Profile page

type EditTitleProps = {
  titleText?: string;
  setTitleText: (text: string) => void;
};

export const EditTitle = ({ titleText, setTitleText }: EditTitleProps) => {
  const [titleEditMode, setTitleEditMode] = useState(false);
  const titleRef = useRef<TextFieldType>(null);
  useRevertEdit(titleRef, setTitleEditMode, 'infoPencil');

  return (
    <StyledDiv>
      {titleEditMode ? (
        <TextField
          multiline
          rows={1}
          required
          value={titleText}
          textAlign="start"
          maxLength={50}
          onChange={e => setTitleText(e.target.value)}
          ref={titleRef}
          style={{
            marginLeft: '20px',
            marginRight: '20px',
          }}
          data-cy="title-edit"
        />
      ) : (
        <Typography
          variant="h2"
          color="primary"
          fontWeight="bold"
          gutterBottom
          style={{
            paddingLeft: '12px',
          }}
          onClick={() => setTitleEditMode(!titleEditMode)}
          ref={titleRef}
          data-cy="title-no-edit"
        >
          {titleText}
        </Typography>
      )}
      <Icon
        data-cy="edit-pencil-name"
        className="infoPencil"
        icon={Pencil}
        size="md"
        color="grey"
        onClick={() => setTitleEditMode(!titleEditMode)}
        style={{
          position: 'relative',
          left: '15px',
          bottom: '10px',
          cursor: 'pointer',
        }}
      />
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin: 0;
  position: relative;
  top: 0px;
`;
