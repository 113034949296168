import RoleType from 'app/constants/ApiRole';
import RolesApi from 'app/services/RolesApi';
import { AppAction } from '..';
import { addRole, deleteRole, setAllRoles, updateRole } from './slice';

export const loadRoles = (): AppAction<Promise<void>> => dispatch => {
  return RolesApi.roles.getRoles().then(async resp => {
    dispatch(setAllRoles(resp));
  });
};

export const createRole =
  (name: string, type: RoleType): AppAction<Promise<void>> =>
  dispatch => {
    return RolesApi.roles.createRole(name, type).then(resp => {
      dispatch(addRole(resp));
    });
  };

export const changeRole =
  (id: string, name: string): AppAction<Promise<void>> =>
  dispatch => {
    return RolesApi.roles.updateRole(id, name).then(resp => {
      dispatch(updateRole({ id: resp.id, changes: resp }));
    });
  };

export const removeRole =
  (id: string): AppAction<Promise<void>> =>
  dispatch => {
    return RolesApi.roles.deleteRole(id).then(resp => {
      dispatch(deleteRole(id));
    });
  };
