export enum ACTIONS {
  // departments
  deleteDepartment = 'deleteDepartment',
  editDepartment = 'editDepartment',
  createDepartment = 'createDepartment',
  // roles
  createRole = 'createRole',
  editRole = 'editRole',
  deleteRole = 'deleteRole',
  // members
  createMember = 'createMember',
  editMember = 'editMember',
  deleteMember = 'deleteMember',
  // pods
  createPod = 'createPod',
  editPod = 'editPod',
  deletePod = 'deletePod',
  loadPods = 'loadPods',
  // leadership
  createLeadership = 'createLeadership',
  editLeadership = 'editLeadership',
  deleteLeadership = 'deleteLeadership',
  // guilds
  createGuild = 'createGuild',
  editGuild = 'editGuild',
  deleteGuild = 'deleteGuild',
  // consultants
  createConsultant = 'createConsultant',
  editConsultant = 'editConsultant',
  deleteConsultant = 'deleteConsultant',
  // default
  default = 'default',
}

type DefaultErrorMessage = {
  title: string;
  description: string;
};

type UserMessaging = {
  toast: {
    [key in ACTIONS]: {
      [key in Severity]?: DefaultErrorMessage;
    };
  };
  backupErrorMessaging: {
    membersStillActive: string;
  };
};

export enum Severity {
  ERROR = 'error',
}

export const UserMessaging: UserMessaging = {
  toast: {
    deleteDepartment: {
      error: {
        title: 'Delete Department Request',
        description:
          'There was an error while trying to delete this department. Please try again.',
      },
    },
    editDepartment: {
      error: {
        title: 'Edit Department Request',
        description:
          'There was an error while trying to edit this department. Please try again.',
      },
    },
    createDepartment: {
      error: {
        title: 'Create Department Request',
        description:
          'There was an error while trying to create this department. Please try again.',
      },
    },
    createRole: {
      error: {
        title: 'Create Role Request',
        description:
          'There was an error while trying to create this role. Please try again.',
      },
    },
    editRole: {
      error: {
        title: 'Edit Role Request',
        description:
          'There was an error while trying to edit this role. Please try again.',
      },
    },
    deleteRole: {
      error: {
        title: 'Delete Role Request',
        description:
          'There was an error while trying to delete this role. Please try again.',
      },
    },
    createMember: {
      error: {
        title: 'Create Member Request',
        description:
          'There was an error while trying to create this member. Please try again.',
      },
    },
    editMember: {
      error: {
        title: 'Edit Member Request',
        description:
          'There was an error while trying to edit this member. Please try again.',
      },
    },
    deleteMember: {
      error: {
        title: 'Delete Member Request',
        description:
          'There was an error while trying to delete this member. Please try again.',
      },
    },
    createPod: {
      error: {
        title: 'Create Pod Request',
        description:
          'There was an error while trying to create this pod. Please try again.',
      },
    },
    editPod: {
      error: {
        title: 'Edit Pod Request',
        description:
          'There was an error while trying to edit this pod. Please try again.',
      },
    },
    deletePod: {
      error: {
        title: 'Delete Pod Request',
        description:
          'There was an error while trying to delete this pod. Please try again.',
      },
    },
    loadPods: {
      error: {
        title: 'Load Pods Request',
        description:
          'There was an error while trying to load pods. Please try again.',
      },
    },
    createLeadership: {
      error: {
        title: 'Create Leadership Request',
        description:
          'There was an error while trying to create this leadership team. Please try again.',
      },
    },
    editLeadership: {
      error: {
        title: 'Edit Leadership Request',
        description:
          'There was an error while trying to edit this leadership team. Please try again.',
      },
    },
    deleteLeadership: {
      error: {
        title: 'Delete Leadership Request',
        description:
          'There was an error while trying to delete this leadership team. Please try again.',
      },
    },
    createGuild: {
      error: {
        title: 'Create Guild Request',
        description:
          'There was an error while trying to create this guild. Please try again.',
      },
    },
    editGuild: {
      error: {
        title: 'Edit Guild Request',
        description:
          'There was an error while trying to edit this guild. Please try again.',
      },
    },
    deleteGuild: {
      error: {
        title: 'Delete Guild Request',
        description:
          'There was an error while trying to delete this guild. Please try again.',
      },
    },
    createConsultant: {
      error: {
        title: 'Create Consultant Request',
        description:
          'There was an error while trying to create this consultant. Please try again.',
      },
    },
    editConsultant: {
      error: {
        title: 'Edit Consultant Request',
        description:
          'There was an error while trying to edit this consultant. Please try again.',
      },
    },
    deleteConsultant: {
      error: {
        title: 'Delete Consultant Request',
        description:
          'There was an error while trying to delete this consultant. Please try again.',
      },
    },
    default: {
      error: {
        title: 'Error',
        description: 'An error occurred. Please try again.',
      },
    },
  },
  backupErrorMessaging: {
    membersStillActive:
      'Group still has active members. If there were errors in deleting a member, please try again.',
  },
};
