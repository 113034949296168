import { useAppSelector } from 'app/store';
import { selectPods } from 'app/store/pods/selectors';
import Leadership from 'app/types/Leadership';
import Pod from 'app/types/Pod';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  LineBreak,
  Results,
  SearchHeader,
  SearchSectionProps,
} from './SearchPage';

export const PodSearchSection = ({ search }: SearchSectionProps) => {
  const navigate = useNavigate();
  const pods = useAppSelector(selectPods);
  const [podResults, setPodResults] = useState<Pod[]>([] as Pod[]);
  const [podCollapse, setPodCollapse] = useState(true);

  const clickPod = (id: string) => {
    navigate(`/pods/${id}`);
  };

  useEffect(() => {
    const filteredPods = pods.filter(team =>
      team.name.toLowerCase().includes(search.toLowerCase()),
    );
    setPodResults(filteredPods);
  }, [search]);

  return (
    <>
      <SearchHeader
        title="Pods"
        size={podResults.length}
        collapse={podCollapse}
        setCollapse={setPodCollapse}
      />
      <LineBreak />
      <Results<Leadership>
        results={podResults}
        collapse={podCollapse}
        navigation={clickPod}
      />
    </>
  );
};
