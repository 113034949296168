import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import { Pod } from 'app/types/Pod';

interface PodsState {
  // Key is department id
  entities: Pod[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
}

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

export const podsAdapter = createEntityAdapter<Pod>({
  selectId: a => a.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = podsAdapter.getInitialState({
  apiStatus: {
    loadPods: defaultApiStatus,
    updatePod: defaultApiStatus,
    addPod: defaultApiStatus,
    removePod: defaultApiStatus,
  },
});

const podsSlice = createSlice({
  name: 'pods',
  initialState,
  reducers: {
    addPod: podsAdapter.addOne,
    updatePod: podsAdapter.updateOne,
    deletePod: podsAdapter.removeOne,
    deletePodMember: podsAdapter.updateOne,
    reloadPod: podsAdapter.setOne,
    setPodsRequest: state => {
      state.apiStatus.loadPods.state = ApiState.LOADING;
      state.apiStatus.loadPods.error = null;
    },
    setPodsSuccess: (state, action: PayloadAction<Pod[]>) => {
      state.apiStatus.loadPods.state = ApiState.IDLE;
      state.apiStatus.loadPods.error = null;
      podsAdapter.setAll(state, action);
    },
    setPodsError: (state, action: PayloadAction<string>) => {
      state.apiStatus.loadPods.state = ApiState.ERROR;
      state.apiStatus.loadPods.error = action.payload;
    },
  },
});

export const {
  addPod,
  updatePod,
  deletePod,
  deletePodMember,
  setPodsRequest,
  setPodsSuccess,
  setPodsError,
  reloadPod: reloadPodAction,
} = podsSlice.actions;

export default podsSlice;
