import Guild from 'app/types/Guild';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.GUILDS);
const guildsApi = {
  guilds: {
    getAllGuilds: () => apiClient.get('').json<Guild[]>(),
    createGuild: (name: string, info?: string) =>
      apiClient
        .post(``, {
          json: {
            name: name,
            info: info,
          },
        })
        .json<Guild>(),
    updateGuild: (id: string, name?: string, info?: string) => {
      const params = { name: name, info: info };
      return apiClient.put(`${id}`, { json: params }).json<Guild>();
    },
    deleteGuild: (id: string) => apiClient.delete(`${id}`).json(),
  },
};
export default guildsApi;
