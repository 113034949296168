import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import IdentityUser from 'app/types/IdentityUser';
import { OktaUser } from 'app/types/OktaUser';

interface AppState {
  initialized: boolean;
  selectedDeptTab: string;
  selectedAdminTab: string;
  currentUser: { identity?: IdentityUser; okta?: OktaUser };
}

const initialState: AppState = {
  initialized: false,
  selectedDeptTab: '',
  selectedAdminTab: 'Role',
  currentUser: {},
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitialized: state => {
      state.initialized = true;
    },
    setSelectedDeptTab: (state, action: PayloadAction<string>) => {
      state.selectedDeptTab = action.payload;
    },
    setSelecetedAdminTab: (state, action: PayloadAction<string>) => {
      state.selectedAdminTab = action.payload;
    },
    setCurrentOktaUser: (state, action: PayloadAction<OktaUser>) => {
      state.currentUser.okta = action.payload;
    },
    setIdentityUser: (state, action: PayloadAction<IdentityUser>) => {
      state.currentUser.identity = action.payload;
    },
  },
});

export const {
  setIsInitialized,
  setSelectedDeptTab,
  setCurrentOktaUser,
  setIdentityUser,
  setSelecetedAdminTab,
} = appSlice.actions;

export default appSlice;
