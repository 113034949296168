import { LeadershipTeam } from 'app/types/Leadership';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const leadershipApiClient = createApiClient(AppConstants.URL.LEADERSHIP);

const leadershipApi = {
  leadership: {
    getAllLeadership: () =>
      leadershipApiClient.get('').json<LeadershipTeam[]>(),
    createLeadership: (name: string, info?: string, dept_id?: string) => {
      const params: { name: string; info?: string; dept_id?: string } = {
        name: name,
      };

      if (info) {
        params.info = info;
      }
      if (dept_id) {
        params.dept_id = dept_id;
      }
      return leadershipApiClient
        .post(``, { json: params })
        .json<LeadershipTeam>();
    },
    updateLeadership: (
      leadership_id: string,
      name?: string,
      info?: string,
      dept_id?: string,
    ) => {
      const params: { name?: string; info?: string; dept_id?: string } = {};
      if (name) {
        params.name = name;
      }
      if (info) {
        params.info = info;
      }
      if (dept_id) {
        params.dept_id = dept_id;
      }
      return leadershipApiClient
        .put(`${leadership_id}`, { json: params })
        .json<LeadershipTeam>();
    },
    deleteLeadership: (leadership_id: string) =>
      leadershipApiClient.delete(`${leadership_id}`).json(),
    getLeadership: (id: string) =>
      leadershipApiClient.get(`${id}`).json<LeadershipTeam>(),
  },
};

export default leadershipApi;
