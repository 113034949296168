import podsApi from 'app/services/PodsApi';
import IdentityUser, { IdentityResponse } from 'app/types/IdentityUser';
import { AppAction } from '..';
import {
  setUsers,
  setUsersError,
  setUsersRequest,
  setUsersSuccess,
} from './slice';

export const loadUsers = (): AppAction<Promise<void>> => async dispatch => {
  dispatch(setUsersRequest());
  try {
    const paginateIdentity = async (
      fetcher: (lastId: string) => Promise<IdentityResponse>,
    ) => {
      let data: IdentityUser[] = [];
      let lastId: string = '';
      let hasMoreData: boolean = true;
      let counter = 0;
      while (hasMoreData && counter < 12) {
        const resp = await fetcher(lastId);
        if (resp.count === 0) {
          break;
        }
        data.push(...resp.Users);
        lastId = resp.lastId;
        hasMoreData = lastId ? true : false;
      }
      return data;
    };
    const getStaff = async () => {
      return paginateIdentity(podsApi.members.getStaff);
    };
    const getContractors = async () => {
      return paginateIdentity(podsApi.members.getContractors);
    };
    const [contractors, staff] = await Promise.all([
      getContractors(),
      getStaff(),
    ]);
    dispatch(setUsers([...contractors, ...staff]));
    dispatch(setUsersSuccess());
  } catch (error) {
    dispatch(setUsersError(error));
  }
};
