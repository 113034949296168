import {
  PayloadAction,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import ApiState from 'app/constants/ApiState';
import ApiStatus from 'app/types/ApiStatus';
import IdentityUser from 'app/types/IdentityUser';

export const identityAdapter = createEntityAdapter<IdentityUser>({
  selectId: a => a.GUID,
  sortComparer: (a, b) =>
    a.displayName?.localeCompare(b.displayName ?? '') ?? 0,
});

const defaultApiStatus: ApiStatus = {
  state: ApiState.IDLE,
  error: null,
};

const initialState = identityAdapter.getInitialState({
  apiStatus: {
    loadUsers: defaultApiStatus,
  },
});

const identitySlice = createSlice({
  name: 'identity',
  initialState,
  reducers: {
    setUsers: identityAdapter.setAll,
    setUsersRequest: state => {
      state.apiStatus.loadUsers.state = ApiState.LOADING;
      state.apiStatus.loadUsers.error = null;
    },
    setUsersSuccess: state => {
      state.apiStatus.loadUsers.state = ApiState.IDLE;
      state.apiStatus.loadUsers.error = null;
    },
    setUsersError: (state, action: PayloadAction<unknown>) => {
      state.apiStatus.loadUsers.state = ApiState.ERROR;
      state.apiStatus.loadUsers.error = action.payload;
    },
  },
});

export const { setUsers, setUsersRequest, setUsersSuccess, setUsersError } =
  identitySlice.actions;
export default identitySlice;
