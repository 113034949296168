import Member from 'app/types/Member';
import { Tag } from 'cfa-react-components';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../App.scss';

// This and the leadershipProfileCard are the same
export type ProfileProps = {
  member: Member;
};
// The values are arguments passed from the PodCardRoleSection.js file.
export const ProfileTag = ({ member }: ProfileProps) => {
  const navigate = useNavigate();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    userId: string | undefined,
  ) => {
    event?.stopPropagation();
    navigate(`/profile/${userId}`);
  };

  return (
    <div>
      {member.isContingent ? (
        <Tag
          className="tag"
          color="tan"
          variant="filled"
          label={member.name}
          style={{ marginTop: 7, marginBottom: 7 }}
          onClick={e => handleClick(e, member.userId)}
        />
      ) : (
        <Tag
          color="default"
          label={member.name}
          style={{ marginTop: 7, marginBottom: 7 }}
          onClick={e => handleClick(e, member.userId)}
        />
      )}
    </div>
  );
};
export default ProfileTag;
