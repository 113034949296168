import guildsApi from 'app/services/GuildsApi';
import { AppAction } from '..';
import {
  addGuild,
  deleteGuild,
  setGuildsError,
  setGuildsRequest,
  setGuildsSuccess,
  updateGuild as updateGuildSlice,
} from './slice';

export const loadGuilds = (): AppAction<Promise<void>> => dispatch => {
  dispatch(setGuildsRequest());
  return guildsApi.guilds
    .getAllGuilds()
    .then(resp => {
      dispatch(setGuildsSuccess(resp));
    })
    .catch(error => {
      console.log(error);
      dispatch(setGuildsError(error));
    });
};

export const createGuild =
  (name: string, info?: string): AppAction<Promise<void>> =>
  dispatch => {
    return guildsApi.guilds.createGuild(name, info).then(data => {
      dispatch(addGuild(data));
    });
  };

export const updateGuild =
  (id: string, newName?: string, newInfo?: string): AppAction<Promise<void>> =>
  dispatch => {
    return guildsApi.guilds.updateGuild(id, newName, newInfo).then(resp => {
      dispatch(updateGuildSlice({ id: id, changes: resp }));
    });
  };

export const removeGuild =
  (id: string): AppAction<Promise<void>> =>
  dispatch => {
    return guildsApi.guilds.deleteGuild(id).then(resp => {
      dispatch(deleteGuild(id));
    });
  };
