export const MenuItems = [
  {
    title: 'Home',
    url: '/home',
    cName: 'nav-links',
  },
  {
    title: 'Pods',
    url: '/pods',
    cName: 'nav-links',
  },
  {
    title: 'Leadership Teams',
    url: '/leadership-teams',
    cName: 'nav-links',
  },
  {
    title: 'Guilds',
    url: '/guilds',
    cName: 'nav-links',
  },
  // {
  //     title: 'Steering Groups',
  //     url: '/steering-groups',
  //     cName: 'nav-links'
  // },
  {
    title: 'Admin',
    url: '/admin',
    cName: 'nav-links',
  },
];

// This above items are imported into Navigation.js. The title becomes the name of the button in the nav bar.
// The url is the path to which file (page) we want to point to.
// The cName is a ref to the class name. This className is then utilized in the stylesheet for Navigation.js
