// import * as packageJson from '../../../package.json';

export default class AppConstants {
  // static get APP_VERSION() {
  //   return packageJson.version
  // }
  static get DEV() {
    return 'dev';
  }
  static get TEST() {
    return 'test';
  }
  static get PROD() {
    return 'prod';
  }
  static get DEFAULT_ENVIRONMENT() {
    return this.DEV;
  }
  static get ENV() {
    return process.env.REACT_APP_ENVIRONMENT ?? 'dev';
  }

  static get BASEURL() {
    if (this.ENV === this.PROD) {
      return 'nufwjwxr3d';
    } else if (this.ENV === this.TEST) {
      return '0web098n1a';
    }
    return 'qtmp8u1tb6';
  }
  static get URL() {
    return {
      PODS: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1`,
      LEADERSHIP: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/leadership`,
      PICTURES: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1`,
      IDENTITY: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1`,
      GUILDS: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/guilds`,
      DEPARTMENT: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/dept`,
      ROLES: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/roles`,
      DEPARTMENTS: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/dept`,
      NEW_PODS: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1/pods`,
      MEMBERS: `https://${this.BASEURL}.execute-api.us-east-1.amazonaws.com/v1`,
      LOCAL: `http://localhost:8000/v2`,
    };
  }
  static get BANNER_AMOUNT() {
    return 3000;
  }
  static get TOAST_DURATION() {
    return 5000;
  }
  static get STAFF_PHOTO_URL() {
    return `https://${this.ENV}-cfa-staff-api-employee-images.s3.amazonaws.com`;
  }
}
