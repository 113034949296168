import { OKTA_STORAGE } from 'app/constants/AppPermissions';

const getPersonalId = () => {
  const oktaCookie = localStorage.getItem(OKTA_STORAGE);
  let personalId = '';

  if (oktaCookie) {
    const token = JSON.parse(oktaCookie);
    personalId = token?.idToken.claims['cfa-guid'];
  }

  return personalId;
};

export { getPersonalId };
