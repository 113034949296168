import { RootState } from '..';
import { leadershipAdapter } from './slice';

export const selectLeadershipReducerState = (store: RootState) =>
  store.leadership;

const leadershipSelectors = leadershipAdapter.getSelectors(
  selectLeadershipReducerState,
);

export const selectLeadership = (store: RootState) =>
  leadershipSelectors.selectAll(store);

export const selectLeadershipById =
  (leadershipId: string) => (store: RootState) =>
    leadershipSelectors.selectById(store, leadershipId);

export const selectLeadershipMembersByLeadershipId =
  (leadershipId: string) => (store: RootState) => {
    const leadership = leadershipSelectors.selectById(store, leadershipId);
    if (leadership) {
      return leadership.members;
    }
    return undefined;
  };
