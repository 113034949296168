import Department from 'app/types/Department';
import AppConstants from 'app/utils/AppConstants';
import createApiClient from './createApiClient';

const apiClient = createApiClient(AppConstants.URL.DEPARTMENTS);
const departmentsApi = {
  departments: {
    getAllDepartments: () => apiClient.get('').json<Department[]>(),
    createDepartment: (name: string, alias?: string, leader?: string) =>
      apiClient
        .post(``, {
          json: {
            name: name,
            alias: alias,
            leader: leader,
          },
        })
        .json<Department>(),
    updateDepartment: (
      id: string,
      name?: string,
      alias?: string,
      leader?: string,
    ) => {
      const params = { name: name, alias: alias, leader: leader };
      return apiClient.put(`${id}`, { json: params }).json<Department>();
    },
    deleteDepartment: (id: string) => apiClient.delete(`${id}`).json(),
  },
};
export default departmentsApi;
